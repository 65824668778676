'use strict';

import {ILogService, IScope} from "angular";
import {AlarmMonitor, AlarmMonitorDefaultConfig, AlarmMonitorType} from "../../../../data/am4.data";
import HelperService from "../../../../services/helper.service";
import RestService from "../../../../services/rest.service";
import {AMWEB_ENDPOINT} from './../../../../constants';
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
import angular = require("angular");

require('./edit.AM4.modal.css');


/* @ngInject */
export default class EditAm4ModalController {
  public $scope: IScope;
  public $uibModalInstance: any;
  public restService: RestService;
  public helperService: HelperService;
  public $log: ILogService;
  public am: AlarmMonitor;
  public isLocal = false;
  public isEditMode = false;
  public isLoading = false;
  public isConnected = false;
  public copiedToClipboard = false;
  public listeners = [];
  public model: AlarmMonitor;
  public copyOfModel: any;
  public form: any;
  public mode: AlarmMonitorModes = AlarmMonitorModes.GENERAL;
  public randomPassword: string;


  public defaultConfig: AlarmMonitorDefaultConfig;
  public defaultConfigs: AlarmMonitorDefaultConfig[] = [];
  public priv: PrivilegeService;
  public hasAM4Edit:boolean= false;
  public hasAM4Create:boolean= false;
  public isEditingFieldsAllowed:boolean=false;


  constructor($scope: IScope, $uibModalInstance, restService: RestService, helperService: HelperService, $log: ILogService, am: AlarmMonitor, privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.helperService = helperService;
    this.$log = $log;
    this.am = am;
    this.priv= privilegeService;

    // TODO (later): use default configs from server
    this.defaultConfigs.push({
      name: 'Standard',
      id: '1'
    });
    this.defaultConfig = this.defaultConfigs[0];

    this.isEditMode = angular.isDefined(am);
    if (this.isEditMode) {
    if (am.mqtt) {
      this.isConnected = true;
    }
  }

    this.model = {
      mqtt: false,
      name: '',
      address: '',
      port: 5556,
      password: '',
      type: AlarmMonitorType.AMWEB,
      receiveStatus: false,
      receiveRoadBlocks: true,
      receiveMarker: false
    } as AlarmMonitor;

    if (!this.isEditMode) {
      this.isEditingFieldsAllowed= this.hasAM4Create;
      this.generatePassword(false);
    }

    this.initListeners();
  }
  checkEditAllowed(){
    this.hasAM4Edit= this.priv.has(RolePrivilege.Addressbook_AM4_Edit);
    this.hasAM4Create= this.priv.has(RolePrivilege.Addressbook_AM4_Create);
    if (this.isEditMode) {
      this.isEditingFieldsAllowed = this.hasAM4Edit;
    }else {
      this.isEditingFieldsAllowed = this.hasAM4Create;
    }
  }

  getAMwebLink(edit: boolean): string {
    if (edit) {
      return AMWEB_ENDPOINT + '/' + this.model.accessId + '/edit';
    } else {
      return AMWEB_ENDPOINT + '/' + this.model.accessId;
    }

  }


  initListeners() {
    this.checkEditAllowed()

    this.$scope.$watch("ctrl.am", (am: AlarmMonitor) => {
      // Init
      if (angular.isDefined(am)) {
        this.model = am;
        this.copyOfModel = angular.copy(this.model);
        this.isLocal = this.model.address === '127.0.0.1';
        this.checkEditAllowed();
      }

    });

  }
  cancel() {
    // Reset data
    if (angular.isDefined(this.copyOfModel)) {
      angular.forEach(this.copyOfModel, (value, key) => {
        this.model[key] = value;
      });
    }

    this.$uibModalInstance.close();
  }

  setLocal() {
    this.model.address = this.isLocal ? '127.0.0.1' : '';
    if (angular.isDefined(this.form)) {
      this.form.ip.$dirty = true;
      this.validateAddress();
    }
  }
  add() {
    // Custom validation
    if (!this.validateAddress()) {
      return;
    }

    if (this.model.type === AlarmMonitorType.AMWEB) {
      this.model.password = this.randomPassword;
    }

    this.isLoading = true;
    this.restService.addAM4(this.model, this.defaultConfig,
      () => {
        this.isLoading = false;
        this.$uibModalInstance.close();
      },
      (response) => {
        //Error occured
        this.isLoading = false;
        this.$log.error(response);
      });
  }

  save(closeWindow: boolean) {
    // Custom validation
    if (!this.validateAddress()) {
      return;
    }


    if (this.model.type === AlarmMonitorType.AMWEB && this.randomPassword) {
      this.model.password = this.randomPassword;
    }

    this.isLoading = true;
    this.restService.editAM(this.model,
      () => {
        this.isLoading = false;
        if (closeWindow) {
          this.$uibModalInstance.close();
        }
      },
      (response) => {
        //Error occured
        this.isLoading = false;
        this.$log.error(response);
      });
  }

  generatePassword(save: boolean) {
    this.randomPassword = this.helperService.generatePassword(20);
    if (save) {
      this.save(false);
    }
  }

  /**
   * Copy the access ID to clipboard
   */
  copyToClipboard() {
    var data = [new ClipboardItem({ 'text/plain': new Blob([this.model.accessId], { type: 'text/plain' }) })];
    navigator.clipboard.write(data).then(() => {
      this.copiedToClipboard = true;
      this.$scope.$applyAsync();
    });
  }

  validateAddress() {
    var isValid = true;

    if (this.model.type === AlarmMonitorType.AMWEB) {
      return true;
    }

    if (this.model.address === '' && !this.model.mqtt) {
      this.form.ip.$setValidity('address', false);
      isValid = false;
    } else {
      this.form.ip.$setValidity('address', true);
    }
    return isValid;
  }


}

export enum AlarmMonitorModes {
  GENERAL = 'GENERAL',
  AM4_SETTINGS = 'AM4_SETTINGS'
}
