'use strict';

import {IRootScopeService, IScope} from "angular";
import {EVehicleStatus, StatusEntryResponse, Vehicle} from "../../../../data/vehicles.data";
import VehicleService from "../../../../services/vehicle.service";
import angular = require("angular");
import RestService from "../../../../services/rest.service";
import HelperService from "../../../../services/helper.service";
import { VehicleAssignedAlarm } from "../../../../data/alarm.data";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";

require('./vehicle.list.component.css');

export default class VehicleListComponent {
  public restrict: string;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
      vehicle: '=',
      fullColor: '=',
      showTitle: '=',
      animation: '=',
      allowSpeakingReset: '='
    }
    this.template = require('./vehicle.list.component.html');

    this.controller = VehicleListComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//vehicle
class VehicleListComponentController {
  public helperService: HelperService;
  public vehicleService: VehicleService;
  public restService: RestService;
  public $uibModal: any;
  public $scope: IScope;
  public vehicle: Vehicle;
  public textColor = '#ffffff';
  public fullColor: boolean = false;
  public showTitle: boolean = false;
  public animation: boolean = true;
  public allowSpeakingReset: boolean = false;
  public isDisplayStatusNumbers = false;

  public isLoading: boolean = false;
  public tooltipIsOpen = false;
  public listeners = [];
  public alarm: VehicleAssignedAlarm;

  constructor($scope: IScope, $rootScope: IRootScopeService, vehicleService: VehicleService, helperService: HelperService, restService: RestService, $uibModal,
    public privilegeService: PrivilegeService) {
    this.helperService = helperService;
    this.vehicleService = vehicleService;
    this.restService = restService;
    this.$scope = $scope;
    this.$uibModal = $uibModal;

    this.$scope.$watch('ctrl.vehicle', (vehicle: Vehicle) => {
      if (angular.isDefined(vehicle)) {
        this.vehicle = vehicle;
        this.textColor = this.vehicle.statusTextColor;
        var changeEvent = 'status.change.' + this.vehicle.id;
        this.listeners.push( $rootScope.$on(changeEvent, (event, data: StatusEntryResponse) => {
          this.vehicle.status = data.status;
          this.vehicle.statusChangedTimestamp = data.timestamp;
          this.vehicle.statusColor = data.color;
          this.textColor = data.textColor;
          this.$scope.$apply();
        }));
      }
    });
    // necessary to keep right value when switching groups of tableau
    this.isDisplayStatusNumbers = this.helperService.getFromStorage("tableauDisplayStatus", false);

    this.listeners.push($rootScope.$on('statusNumber.statusTableau', (event, isActive) => {
      this.isDisplayStatusNumbers = isActive;
    }));

    //This listener is for later realtime status updates


    // Destroy listener
    this.$scope.$on('$destroy', () => this.listeners.forEach((listener)=>{
      listener();
    }));
  }

  getName(){
    if (angular.isDefined(this.vehicle.shortName) && this.vehicle.shortName){
      return this.vehicle.shortName;
    }
    return this.vehicle.name;
  }

  isSpecialIcon(){
    switch(this.vehicle.status){
      case EVehicleStatus.STATUS_0:
      case EVehicleStatus.STATUS_5:
      case EVehicleStatus.STATUS_C:
        return true;
      default:
        return false;
    }
  }

  /**
   * The vehicle has been selected
   */
  selectVehicle() {
    var isResetRequest = false;
    if (this.allowSpeakingReset) {
      switch (this.vehicle.status) {
        case EVehicleStatus.STATUS_0:
        case EVehicleStatus.STATUS_5:
          isResetRequest = true;
          break;
      }
    }

    if (isResetRequest) {
      this.resetPendingSpeakingRequest();
    } else {
      this.openVehicle();
    }
  }

  /**
   * Open the vehicle modal
   */
  private openVehicle() {
    if (!this.privilegeService.has(RolePrivilege.Station_Vehicles_ReadDetails)) {
      return;
    }
    this.$uibModal.open({
      template: require('../../../modals/wache/vehicle.modal/vehicle.modal.html'),
      controller: 'VehicleModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        vehicle: () => {
          return this.vehicle;
        },
        vehicleId: () => {
          return this.vehicle.id;
        },
        okFunction: () => {

        }
      }
    });
  };

  /**
   * Reset the pending STATUS_5 and STATUS_0
   */
  resetPendingSpeakingRequest() {
    this.isLoading = true;
    this.vehicleService.schedulePendingResetTimerTask(this.vehicle).finally(() => this.isLoading = false);
  }

  loadAlarm() {
    this.restService.loadVehicleWithAlarm(this.vehicle.id).then(result => {
      this.alarm = result;
      this.tooltipIsOpen = true;
      setTimeout(() => {
        this.tooltipIsOpen = false;//Auto close tooltip
        this.$scope.$applyAsync();
      }, 2000);
    }).catch(() => {
      this.tooltipIsOpen = false;
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }
}