'use strict';

import RestService from "../../../../../services/rest.service";
import { IHttpService, IScope, IWindowService } from "angular";
import { PaginatedKeywordResponse } from "../../../../../data/alarm.data";
import { KeywordSettings } from "../../../../../data/admin.data";

require('./admin.keywords.component.scss');

export default class AdminKeywordsSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.keywords.component.html');
    this.scope = {
    };
    this.controller = AdminKeywordsSettingsController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}

/* @ngInject */
class AdminKeywordsSettingsController {
  private isLoading: boolean = false;
  private restService: RestService;
  private $scope: IScope;
  private $window: IWindowService;
  private $http: IHttpService;
  public FileUploader: any;
  public $uibModal: any;

  private data: PaginatedKeywordResponse;
  private settings: KeywordSettings;
  private currentPage = 1;
  private limit = 100;


  constructor($scope: IScope, restService: RestService, $http: IHttpService, $window: IWindowService, $uibModal, FileUploader) {
    this.restService = restService;
    this.$scope = $scope;
    this.$window = $window;
    this.$http = $http;
    this.FileUploader = FileUploader;
    this.$uibModal = $uibModal;
    this.load();
  }

  private load(): void {
    this.isLoading = true;

    this.restService.loadKeywordSettings().then(settings => {
      this.settings = settings;
      this.restService.loadKeywordsPaginated(this.currentPage - 1, this.limit).then(result => {
        this.data = result;
      }).finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });

    }).catch(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });

  }

  public save(): void {
    this.isLoading = true;
    this.restService.saveKeywordSettings(this.settings).then(settings => {
      this.settings = settings;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  /**
   * Import keywords as CSV
   */
  public import(): void {
    var modalInstance = this.$uibModal.open({
      template: require('./../../../../modals/misc/import.modal/import.modal.html'),
      controller: 'ImportModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        uploader: () => {
          let fileUploader = new this.FileUploader();
          return fileUploader;
        },
        url: () => {
          return '/keywords/upload';
        }
      }
    });

    modalInstance.result.then(() => {
      this.load();
    });
  }

  /**
   * Export keywords as CSV
   */
  public export(): void {
    this.$window.open(
      this.restService.getBaseUrl() +
      '/keywords/download?Authorization=' +
      this.$http.defaults.headers.common.Authorization,
      '_blank'
    );
  }

}

