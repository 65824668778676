'use strict';

import { ILogService, IRootScopeService, IScope, ITimeoutService } from "angular";
import angular = require("angular");
import { AlarmData } from "../../../../data/alarm.data";
import MapService from "../../../../services/map.service";
import * as L from 'leaflet';


//alarmMap
export default class AlarmMapComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E'
    this.template = require('./alarm.map.component.html');
    this.scope = {
      alarm: '='
    };
    this.controller = AlarmMapComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AlarmMapComponentController {
  public leafletData: any;
  public $timeout: ITimeoutService;
  public $scope: IScope;
  public hasCoordinates = false;
  public hasAddress = false;
  public $log: ILogService;
  public map: any;
  public alarm: AlarmData;
  public mapService: MapService;

  constructor($rootScope: IRootScopeService, $scope: IScope, leafletData, $timeout: ITimeoutService, $log: ILogService, mapService: MapService) {
    this.leafletData = leafletData;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.$log = $log;
    this.mapService = mapService;
    this.map = {
      center: {
        lat: 48.374107,
        lng: 10.915555,
        zoom: 16
      }
    };

    this.leafletData.getMap("mymap").then(map => {
      map.attributionControl.setPrefix('<a style="color:black !important" href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a>');
      this.initLayers(map);
    })


    $scope.$watch('ctrl.alarm', (oldData, newData: AlarmData) => {
      if (newData) {
        this.alarm = newData;
        this.updateMap();
      }
    });

    $rootScope.$on('updateMap', (event, data) => {
    });
  }

  initLayers(map: L.Map) {
    // we have to remove the default OSM layer
    map.eachLayer(layer => {
      map.removeLayer(layer)
    })
    let layers = this.mapService.getBaseLayers();
    L.control.layers(layers).addTo(map);

    let selectedLayer = this.mapService.getSelectedLayer();
    if(selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(map);

    let mapService = this.mapService
    map.on('baselayerchange', function(e) {
      mapService.saveLayer(e.name);
    });
  }

  /**
   * Updates the build in map after selecting the tab header
   * */
  updateMap() {
    if (angular.isUndefined(this.alarm)) {
      return;
    }
    this.hasAddress = angular.isDefined(this.alarm.parameters['location_dest']);
    this.hasCoordinates = angular.isDefined(this.alarm.parameters['lat']) && angular.isDefined(this.alarm.parameters['lng']);


    //Update the map
    if (angular.isDefined(this.alarm.parameters['lat'])) {
      this.leafletData.getMap().then(
        (leafletMap) => {
          this.map = leafletMap;
          var msg = this.alarm.parameters['location_dest'];
          if (angular.isDefined(this.alarm.parameters['building'])) {
            msg = this.alarm.parameters['building'];
          }

          this.map.markers = {
            address: {
              lat: parseFloat(this.alarm.parameters['lat']),
              lng: parseFloat(this.alarm.parameters['lng']),
              message: msg,
              draggable: false
            }
          };

          this.map.center = {
            lat: parseFloat(this.alarm.parameters['lat']),
            lng: parseFloat(this.alarm.parameters['lng']),
            zoom: 16
          };



          this.$timeout(() => {
            this.$log.debug('Map updated');
            this.map.invalidateSize(true);
          }, 300);

        },
        (err) => {
          this.$log.error(err);
        }
      );


    }
  };

  getURL() {
    return encodeURIComponent(this.alarm.parameters['location_dest']);
  };
}
