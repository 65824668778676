import RestService from "../../../../services/rest.service";
import { IRootScopeService, IScope } from "angular";
import { User } from "../../../../data/admin.data";
import angular = require("angular");

require('./admin.users.os.component.scss')


export default class AdminUsersOSComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.template = require('./admin.users.os.component.html');
    this.scope = {
      user: '=',
    };
    this.controller = UserOSController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}
/* @ngInject */
class UserOSController {
  public restService: RestService;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public user: User;
  public isLoading = false;

  constructor(restService: RestService, $scope: angular.IScope, $rootScope: angular.IRootScopeService) {
    this.restService = restService;
    this.$scope = $scope;
    this.$rootScope = $rootScope;

    this.$scope.$watch('ctrl.user', (oldValue, newValue: User) => {
      if (angular.isDefined(newValue)) {
        this.user = newValue;
      }
    });
  }

}