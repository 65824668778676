import { Vehicle, StatusEntryResponse, VehicleStatusHistory } from './../../../../data/vehicles.data';
import { IScope, IRootScopeService, ILogService } from 'angular';
import RestService from '../../../../services/rest.service';
import angular = require("angular");

'use strict';

require('./status.component.css');

export default class StatusComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E'
    this.scope = {
      vehicle: '='
    }
    this.template = require('./status.component.html');

    this.controller = StatusController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//status
/* @ngInject */
class StatusController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public restService: RestService;
  public helperService: any;
  public vehicle: Vehicle;
  public isLoading: boolean;

  public limit: number = 15;
  public currentPage = 0;
  public data = {
    currentPage: 1
  } as VehicleStatusHistory;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, restService: RestService, helperService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.restService = restService;
    this.helperService = helperService;


    this.$scope.$watch('ctrl.vehicle', (oldValue, newVehicle: Vehicle) => {
      if (angular.isDefined(newVehicle)) {
        this.pageChanged();
      }
    });


  }

  pageChanged() {
    this.isLoading = true;
    //Current page starts with 0
    this.restService.getStatus(this.vehicle, this.currentPage, this.limit, (response) => {
      this.isLoading = false;
      this.data = response.data;
    }, (errorResponse) => {
      this.isLoading = false;
      this.$log.error(errorResponse);
    });
  };

  nextPage() {
    this.currentPage++;
    this.pageChanged();
  }

  previousPage() {
    if (this.currentPage === 0) return;
    this.currentPage--;
    this.pageChanged();
  }
}
