import { EVehicleType } from './../../../../data/vehicles.data';
'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import { ELoginType, UserAccount } from "../../../../data/account.data";
import { EVehicleStatus, Vehicle } from "../../../../data/vehicles.data";
import RestService from '../../../../services/rest.service';
import PrivilegeService from '../../../../services/privilege.service';
import {RolePrivilege} from '../../../../data/privileges.enum';

require('./vehicle.overview.component.css');

export default class VehicleOverviewComponent {
  public restrict: string;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
      vehicle: '='
    }
    this.template = require('./vehicle.overview.component.html');

    this.controller = VehicleOverviewComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//vehicle
class VehicleOverviewComponentController {
  public helperService: any;
  public dataService: any;
  private restService: RestService;
  public $scope: IScope;
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public status: string[] = ['1', '2', '3', '4', '6', '7', '8', '9', 'C'];
  public color: string;
  public vehicle: Vehicle;
  public isLoading: boolean = false;
  public isPersonLoggedIn: boolean = false;
  public searchAlternativeVehicle = false;
  public account: UserAccount;
  public hasEditPermission: boolean;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: ILogService, dataService, restService: RestService, helperService,
    public privilegeService: PrivilegeService) {
    this.helperService = helperService;
    this.dataService = dataService;
    this.restService = restService;
    this.$scope = $scope;
    this.$log = $log;
    this.$rootScope = $rootScope;
    this.hasEditPermission = this.privilegeService.has(RolePrivilege.Station_Vehicles_Edit);
    if (this.dataService.hasAccount()){
      this.account = this.dataService.getAccount();
    }
    this.isPersonLoggedIn = (dataService.getAccount() as UserAccount).loginType === ELoginType.PERSON;
  }

  /**
 * Manually set status
 */
  setStatus(status) {
    this.$log.info('Setting status: ' + status);
    this.isLoading = true;
    this.dataService.updateVehicleStatus(this.vehicle, status, (vehicle: Vehicle) => {
      this.vehicle.status = vehicle.status;
      this.vehicle.statusColor = vehicle.statusColor;
      this.$rootScope.$emit('status.change', this.vehicle);
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
    });
  }

  setVehicleType(type: EVehicleType) {
    this.vehicle.vehicleType = type
  }

  hasVehicleError(value: number): boolean {
    if (!this.vehicle) return false;
    if (this.vehicle.height === 0 && this.vehicle.length === 0 && this.vehicle.width === 0) {
      // All values are 0 -> OK
      return false;
    }
    // At least one value is not empty
    if (value === 0) {
      return true;
    }
    return false;
  }

  /**
   * Search for vehicles
   * @param queryString
   */
  getVehicles(queryString: string) {
    return this.restService.searchForVehicles(queryString);
  }

  removeAlternativeVehicle() {
    this.vehicle.alternativeVehicle = undefined;
    this.vehicle.alternativeVehicleId = undefined;
  }

  alternativeVehicleSelected(vehicle: Vehicle) {
    this.vehicle.alternativeVehicle = {
      vehicleId: vehicle.id,
      name: vehicle.name
    };
    this.vehicle.alternativeVehicleId = vehicle.id;
  }
}