'use strict';

import angular = require("angular");
import { not } from "angular-ui-router";
import { ETimeUnit, EventNotification } from "../../../../../data/event.data";

require('./event.notification.component.css');

export default class EventNotificationComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E';
    this.scope = {
      notification: '='
    };
    this.template = require('./event.notification.component.html');

    this.controller = EventNotificationController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class EventNotificationController {

  private maxValue = 0;
  private notification: EventNotification;
  private $scope: angular.IScope;

  constructor($scope: angular.IScope) {
    this.$scope = $scope;
    this.$scope.$watch('ctrl.notification', (notification: EventNotification) => {
      this.notification = notification;
      this.changeUnit(this.notification.timeUnit);
    });
  }

  changeUnit(timeUnit: ETimeUnit) {
    this.notification.timeUnit = timeUnit;
    switch (timeUnit) {
      case ETimeUnit.DAYS:
        this.maxValue = 30;
        break;
      case ETimeUnit.HOURS:
        this.maxValue = 72;
        break;
      case ETimeUnit.MINUTES:
        this.maxValue = 60 * 24;
        break;
    }
    if (this.notification.duration > this.maxValue) {
      this.notification.duration = this.maxValue;
    }
  }
}