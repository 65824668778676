'use strict';

import { ILogService, IRootScopeService, IScope, ITimeoutService } from "angular";
import { UserAccount } from "../../../../data/account.data";
import { RevisionListPluginEntry } from "../../../../data/revisionListResponse.data";
import HelperService from "../../../../services/helper.service";
import RestService from "../../../../services/rest.service";

require('./admin.revision.component.css');

export default class AdminRevisionComponent {
  public restrict: string;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA'
    this.scope = {}
    this.template = require('./admin.revision.component.html');
    this.controller = AdminRevisionComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class AdminRevisionComponentController {
  public $log: ILogService;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $uibModal: any;
  public restService: RestService;
  public isLoading: boolean = false;
  public $timeout: ITimeoutService;
  public isChangingRevision: boolean;
  public dataService: any;
  public account: UserAccount;
  public helperService: HelperService;

  public revisionPlugins1: Map<string, RevisionListPluginEntry>;
  public revisionPlugins2: Map<string, RevisionListPluginEntry>;


  constructor($rootScope: IRootScopeService, $log: ILogService, $scope: IScope, $timeout: ITimeoutService, $uibModal: any,
    restService: RestService, dataService, helperService: HelperService) {
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$timeout = $timeout;
    this.$uibModal = $uibModal;
    this.$scope = $scope;
    this.restService = restService;
    this.dataService = dataService;
    this.helperService = helperService;

    if (dataService.hasAccount()) {
      this.account = dataService.account;
    }

    this.getPluginsInRevision();
  }

  getPluginsInRevision() {
    this.restService.getPluginsForRevisionWithStatus().then(plugins => {

      this.revisionPlugins1 = plugins.first;
      this.revisionPlugins2 = plugins.second;
      this.$scope.$applyAsync();

    })
  }

  toggleAllPlugins(inRevision: boolean) {
    if (!inRevision) {
      // Plugins are in revision, we set all to active again
      this.restService.setAllPluginsNotInRevision().then(() => {
        this.getPluginsInRevision();
        this.$rootScope.$emit('pluginRevision.change');
      });
    } else {
      // Plugins are not in revision, we set all into revision
      this.restService.setAllPluginsInRevision().then(() => {
        this.getPluginsInRevision();
        this.$rootScope.$emit('pluginRevision.change');
      });
    }
  }

  /**
   * Turn revision mode on
   */
  turnOn() {
    this.isChangingRevision = true;
    this.restService.changeRevisionMode(true,
      (response) => {
        this.isChangingRevision = false;
        this.account.inRevisionMode = response.data.revision;
      },
      (err) => {
        this.isChangingRevision = false;
        this.$log.error('Could not change revision mode');
        this.$log.error(err);
      });
  };

  /**
   * Turn revision mode off
   */
  turnOff() {
    this.isChangingRevision = true;
    this.restService.changeRevisionMode(false,
      (response) => {
        this.isChangingRevision = false;
        this.dataService.getAccount().inRevisionMode = response.data.revision;
      },
      (err) => {
        this.isChangingRevision = false;
        this.$log.error('Could not change revision mode');
        this.$log.error(err);
      });
  }

  changeStateForPlugin(plugin: RevisionListPluginEntry) {

    if (!plugin.isRevisionActive) {
      this.restService.addRevisionForPlugin(plugin.className).then(() => {
        plugin.isRevisionActive = true;
        this.$scope.$applyAsync();
        this.$rootScope.$emit('pluginRevision.change');
      });
    } else {
      this.restService.removeRevisionForPlugin(plugin.className).then(() => {
        plugin.isRevisionActive = false;
        this.$scope.$applyAsync();
        this.$rootScope.$emit('pluginRevision.change');
      });
    }
  }

  getIcon(plugin: RevisionListPluginEntry) {
    return this.helperService.getIconForPluginname(plugin.className);
  }
}
