import { EServerMode } from "./socket.data";
import {Unit} from "./unit.data";

export interface ProtocolHistory {
    data: Array<ProtocolEntry>;
    length: number;
    totalElements: number;
    totalPages: number;
    currentPage: number;
}

export interface ProtocolEntry {
    message: string;
    info: string;
    timestamp: number;
    source: EProtocolSource;
    hasAdditionalInformation: boolean;
    id: string;
}

export interface VehicleSwapEntry {
    timestamp: number;
    user: string;
    sourceVehicleId: string;
    sourceVehicleName: string;
    destVehicleId: string;
    destVehicleName: string;
    message: string;
}

export interface VehicleSwapHistory {
    data: Array<VehicleSwapEntry>;
    length: number;
    totalElements: number;
    totalPages: number;
    currentPage: number;
}

export interface ProtocolEntryAdditionalInformation {
    content: string;
}


export interface Relais {
    id: string;
    name: string;
    internalName: string;
    port: number;
    group: ERelaisGroup;
    state: ERelaisState;
    type: ERelaisType;
    impulsTime: number;
    licenced: boolean;
}

export enum ERelaisType {
    STATE = 'STATE',
    IMPULS = 'IMPULS'
}


export enum ERelaisState {
    ON = 'ON',
    OFF = 'OFF'
}

export enum ERelaisGroup {
    SPEAKER = 'SPEAKER',
    LIGHT = 'LIGHT',
    MISC = 'MISC',
    RESERVED = 'RESERVED',
    GATE = 'GATE',
    SECURITY = 'SECURITY'
}

export enum EProtocolSource {
    ALARM = 'ALARM',
    FE2 = 'FE2',
    LOGIN = 'LOGIN',
    LOGIN_FAILED = 'LOGIN_FAILED',
    PIPELINE_EDIT = 'PIPELINE_EDIT',
    USER_ADD = 'USER_ADD',
    USER_DELETE = 'USER_DELETE',
    UNIT_ADD = 'UNIT_ADD',
    UNIT_DELETE = 'UNIT_DELETE',
    INPUT = 'INPUT',
    RELAIS_ON = 'RELAIS_ON',
    RELAIS_OFF = 'RELAIS_OFF',
    SYNC_OBJECTS = 'SYNC_OBJECTS',
    SYNC_ROADBLOCKS = 'SYNC_ROADBLOCKS',
    MESSAGE = 'MESSAGE'
}

export enum ELogLevel {
    TRACE = 'TRACE',
    DEBUG = 'DEBUG',
    INFO = 'INFO',
    WARN = 'WARN',
    ERROR = 'ERROR'
}

export interface MapsUsage {
    limit: number,
    used: number,
    remaining: number
}
export interface SmsUsagePerUser {
    user: string;
    months: number[];
}

export interface SmsUsage {
    year: number;
    availableCredits: number;
    users: SmsUsagePerUser[];
}

export interface UserAdminContext {
    name: string;
    organisation: string;
    id: string;
    has2FA: boolean;
    admin: boolean;
    hasOnlineService: boolean;
    onlineServiceName: string;
}

export interface User {
    name: string;
    id: string;
    admin: boolean;
    licenceDetails: LicenceDetailsProUser;
    hasOnlineService: boolean;
    onlineServiceName: string;
}

export interface LicenceDetailsProUser {
    nbrOfPersons: number;
    nbrOfAM3s: number;
    nbrOfAMLs: number;
    nbrOfObjects: number;
    nbrOfDangerousGoods: number;
    nbrOfHereMaps: number;
    nbrOfKohlhammer: number;
    nbrOfVehicleCards: number;
    nbrOfLocationMaps: number;
}

export interface UpdateUserRequest {
    name: string;
}

export interface OrganisationLicenceSetAndAvailableData {
    userId: string;
    numbersForLicences: SingleLicenceSetAndAvailable[];
}

export interface SingleLicenceSetAndAvailable {
    usedNbrFE2: number;
    usedNbrOrganisation: number;
    availableInFE2Licence: number;
    setNbrOrganisation: number;
    licenceType: AdjustLicenceType;

}

export enum AdjustLicenceType {
    PERSONS = 'PERSONS',
    AMOBILE = 'AMOBILE',
    AM4 = 'AM4',
    OBJECTS = 'OBJECTS',
    DANGEROUS_GOODS = 'DANGEROUS_GOODS',
    HERE_MAPS = 'HERE_MAPS',
    KOHLHAMMER = 'KOHLHAMMER',
    VEHICLE_CARDS = 'VEHICLE_CARDS',
    LOCATION_MAPS = 'LOCATION_MAPS'
}
export interface UpdateOrganisationLicenseRequest {
    newLicenceDetailsForUser: LicenceDetailsProUser;
}

export interface AdminAddressbookResponse {
    data: DatabaseAdminPersonResponse[],
    totalElements: number,
    totalPages: number,
    currentPage: number;
}

export interface DatabaseAdminPersonResponse {
    displayName: string,
    personID: string,
    user: string,
    note: string
}

export interface DuplicateGlobalSettings {
    defaultTime: number,
    useText: boolean;
    ignoreUpdatedAlarms: boolean
}

export interface CreateOrganisationRequest {
    name: string,
    password: string,
    createOnlineService: boolean;
}


export interface Voice {
    name: string;
    description: string;
    gender: string;
    culture: string;
}

export interface MqttSetting {
    name: string;
}

export interface MiscSettings {
    resetTimeStatus5: number;
    resetTimeStatusC: number;
    massAlertTimeInSeconds: number;
    massAlertSeparator: string;
    massAlertResetAfterEveryAlert: boolean;
    massAlertCollectFeedbacks: boolean;
    expressAlertTimeInSeconds: number;
    expressAlertSeparator: string;
    expressAlertDontWaitForText: boolean;
    expressAlertCancelIfTextNotAvailable: boolean;
    allowAllUsersToAccessAllUnits: boolean;
    shareAdminAddressbook: boolean;
    shareAdminRoles: boolean;
    sharePluginTemplates: boolean;
    enablePersonShareFromUserContext: boolean;
    deleteRoadblocks: boolean;
    enforceCustomerSmsKey: boolean;
    enableAutoUpdate: boolean;
    ttsSpeed: number;
    voice?: Voice;
    allVoices: Voice[];
    wachalarmSource: string;
    mqttBroker: MQTTBroker;
    personDuplicateAlarmActive: boolean;
    personDuplicateAlarmTimeInHours: number;
}

export enum MQTTBroker {
    CLUSTER = 'CLUSTER', LEGACY = 'LEGACY'
}


export interface GeneralResponse {
    message: string;
}

export interface WebserverSettings {
    title: string;
    port: number;
    externalAddress: string;
    externalAddressAMweb: string;
    use2fa: boolean;
    useSsl: boolean;
    hostnameValidation: boolean;
    expiryDuration: number;
    restrictedIps: string;
    keystore: string;
    password: string;
}

export interface MasterSlaveSettings {
    mode: EServerMode;
    masterIp: string;
    masterPort: number;
    allowSelfSigned: boolean;
    interval: number;
}

export interface MailAccountSettings {
    server: string;
    username: string;
    sender: string;
    password: string;
    auth: boolean;
    ssl: boolean;
    port: number;
    receiver: string[];
    smimeSigningPath: string;
    smimeSigningPassword: string;
}

export interface OutputPluginSettings {
    first: Map<string, DeactivatedPluginListEntry>;
    second: Map<string, DeactivatedPluginListEntry>;
}

export interface DeactivatedPluginListEntry {
    className: string;
    deactivated: boolean;
}

export interface KeywordSettings {
    field: string;
    matchingActive: boolean;
    overwriteKeyword: boolean;
}

export interface ReplacementSettings {
    data: string;
    trimWhitespace: boolean;
}

export interface OnlineServiceMapping {
    id: number;
    idToDisplay: string;
    inUse: boolean;
    name: string;
}

export interface UpdateOnlineServiceMapping {
    userId: string;
    osId: number;
}

export interface OutageSettings {
    hasMailAccount: boolean;
    outageActive: boolean;
    dailyMailActive: boolean;
    warningActive: boolean;
    dailyMailTime: string;
    outageId: string;
    outageName: string;
    outageSystems: OutageSystem[];
}

export interface OutageSystem {
    name: string;
    id: string;
}

export interface FeedbackUnitGroupSimple{
  name: string;
  id: string;
  delayTime: number;
}
export interface FeedbackGroupsUnits {
  id: string;
  units: Unit[];
}