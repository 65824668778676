'use strict';

import { IRootScopeService, IScope, ILogService } from "angular";
import { UserAccount } from "../../../../data/account.data";
import { } from "../../../../data/basefield.data";
import { Vehicle, VehicleStatusHistory, VehicleStatusHistoryResponse } from "../../../../data/vehicles.data";
import RestService from "../../../../services/rest.service";
import { WacheTabs } from "../../../views/wache.view.component/wache.view.component";


require('./history.component.css');

export default class HistoryComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public require: any;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./history.component.html');
    this.scope = {
    };
    this.controller = HistroyComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}




/* @ngInject */
class HistroyComponentController {
  public restService: RestService;
  public $log: ILogService;
  public $uibModal;
  public helperService;
  public dataService;
  public Notification;
  public $translate;
  public listeners = [];

  public isLoading: boolean = false;
  public isAdmin: boolean = false;
  public deleteDays = [0, 7, 14, 30, 60, 90, 180];
  public selectedDayRange = 0;

  public currentPage = 0;
  public data = {
    currentPage: 1
  } as VehicleStatusHistory;
  public limit = 100;


  constructor($log: ILogService, $scope: IScope, $rootScope: IRootScopeService, $uibModal, Notification, $translate, dataService, helperService, restService: RestService) {
    this.restService = restService;
    this.$uibModal = $uibModal;
    this.Notification = Notification;
    this.$translate = $translate;
    this.$log = $log;
    this.dataService = dataService;
    this.helperService = helperService;


    this.listeners.push($rootScope.$on('update.selectedTabWache', (event, selectedTab: WacheTabs) => {
      if (selectedTab === WacheTabs.HISTORY) {
        // Check if is admin
        this.isAdmin = (this.dataService.getAccount() as UserAccount).admin;
        this.loadHistory();
      }
    }));

    $scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  loadHistory() {
    this.isLoading = true;
    //Current page starts with 0
    this.restService.getStatus(undefined, this.currentPage, this.limit, (response: VehicleStatusHistoryResponse) => {
      this.isLoading = false;
      this.data = response.data;
    }, (error) => {
      this.isLoading = false;
      this.$log.error(error);
    });
  };

  changeDeleteDays(value: number) {
    this.selectedDayRange = value;
  }

  nextPage() {
    this.currentPage++;
    this.loadHistory();
  }

  previousPage() {
    if (this.currentPage === 0) return;
    this.currentPage--;
    this.loadHistory();
  }

  /**
   * Delete status history
   */
  delete() {
    // Load vehicleIDs
    this.dataService.getVehicles(false, (vehicles: Vehicle[]) => {
      const vehicleIds = [];
      vehicles.forEach(v => vehicleIds.push(v.id));

      this.$uibModal.open({
        template: require('../../../modals/admin/confirm.clear.database.modal/confirm.clear.database.modal.html'),
        controller: 'ConfirmClearDatabaseModalController',
        controllerAs: 'ctrl',
        size: 'md',
        resolve: {
          selectedDayRange: () => {
            return 'ADMIN.STATUS_DELETE_DAYS.' + this.selectedDayRange;
          },
          okFunction: () => {
            return () => {
              this.isLoading = true;
              this.restService.deleteStatusForVehicles(vehicleIds, this.selectedDayRange, response => {
                this.$translate(['ADMIN.DELETED_TITLE']).then((translations) => {
                  this.Notification.success({
                    message: response.message,
                    title: translations['ADMIN.DELETED_TITLE']
                  });
                });
                this.loadHistory();
                this.isLoading = false;
                this.data.currentPage = 1;
              },
                (response) => {
                  //Error occured
                  this.isLoading = false;
                  this.$log.error(response);
                });
            };
          }
        }
      });
    });


  }
}