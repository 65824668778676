'use strict';

import { IScope } from "angular";
import { AlarmGroupSimple } from "../../../../data/alarmgroup.data";
import { Person, PersonsAddressbookResponse } from "../../../../data/person.data";
import RestService from "../../../../services/rest.service";
import { SortParams } from "../../../views/addressbook.view.component/addressbook.view.component";

require('./person.base.field.css');

export default class PersonBaseFieldComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./person.base.field.html');
    this.scope = {
      field: '=',
      isDerivedFromTemplate: '=',
      filterBy: '=',
      mode: '='
    };
    this.controller = PersonBaseFieldController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}


/* @ngInject */
class PersonBaseFieldController {
  public tab: PersonFieldTab;
  public field: any;
  public isLoading = false;
  public addressbook: PersonsAddressbookResponse;
  public alarmGroups: AlarmGroupSimple[] = [];
  public restService: RestService;
  public $scope: IScope;
  public filterBy: string;
  public listeners = [];

  private params = {
    sortType: 'displayName', // set the default sort type
    searchFilter: '',
    currentPage: 0,
    totalElements: 0,
    pageSize: 50
  } as SortParams;

  constructor($scope: IScope, restService: RestService) {
    this.tab = PersonFieldTab.PERSONS;
    this.restService = restService;
    this.$scope = $scope;

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });

    this.listeners.push(this.$scope.$watch('ctrl.filterBy', (filterBy: string) => {
      this.filterBy = filterBy;
      if (this.filterBy) {
        // Only load persons if there is any filter

      }
    }));

    this.listeners.push(this.$scope.$watch('ctrl.mode', (newMode: string) => {
      this.tab = newMode as PersonFieldTab;
      switch (this.tab) {
        case PersonFieldTab.GROUPS:
          // Load alarm groups
          this.isLoading = true;
          this.restService.loadAlarmGroupsSimple().then(alarmGroups => {
            this.alarmGroups = alarmGroups;
          }).finally(() => {
            this.isLoading = false;
            this.$scope.$applyAsync();
          });
          break;
        case PersonFieldTab.PERSONS:
          this.pageChanged();
          break;
      }
    }));



  }


  /**
   * Returns true, if the given person is currently selected
   * @param {the person} person
   */
  isPersonSelected(person: Person) {
    for (var i = 0; i < this.field.value.length; i++) {
      if (this.field.value[i] === person.personID) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns true, if the given alarmGroup is currecntly selected
   * @param {the alarmGroup} group
   */
  isGroupSelected(group: AlarmGroupSimple) {
    for (var i = 0; i < this.field.value.length; i++) {
      if (this.field.value[i] === group.id) {
        return true;
      }
    }
    return false;
  }

  /**
   * Select the given alarmGroup
   * @param {alarmGroup} group
   */
  selectGroup(group: AlarmGroupSimple) {
    if (this.isGroupSelected(group)) {
      //Remove
      var indexToDelete = -1;
      for (var i = 0; i < this.field.value.length; i++) {
        if (this.field.value[i] === group.id) {
          indexToDelete = i;
          break;
        }
      }
      if (indexToDelete > -1) {
        this.field.value.splice(indexToDelete, 1);
      }
    } else {
      //Add
      this.field.value.push(group.id);
    }
  }

  /**
   * Select a given person
   * @param {person} person
   */
  selectPerson(person: Person) {
    if (this.isPersonSelected(person)) {
      //Remove
      while (true) {
        var indexToDelete = -1;
        for (var i = 0; i < this.field.value.length; i++) {
          if (this.field.value[i] === person.personID) {
            indexToDelete = i;
            break;
          }
        }
        if (indexToDelete > -1) {
          this.field.value.splice(indexToDelete, 1);
        } else {
          break;
        }
      }
    } else {
      //Add
      this.field.value.push(person.personID);
    }
  }

  /**
   * Reset the search filter
   * */
  resetSearchAndReload() {
    this.params.searchFilter = '';
    this.pageChanged();
  };

  search() {
    this.params.currentPage = 0;
    this.pageChanged();
  }

  pageChanged() {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.restService.loadPersons(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1,
        this.params.searchFilter,
        this.params.sortReverse ? 'DESC' : 'ASC',
        this.params.pageSize,
        this.filterBy,
        false)
        .then((result: PersonsAddressbookResponse) => {
          this.addressbook = result;
          this.params.totalElements = this.addressbook.totalElements;
          this.params.totalPages = this.addressbook.totalPages;
        }).finally(() => {
          this.isLoading = false;
          this.$scope.$applyAsync();
          resolve();
        });
    });
  }
}

enum PersonFieldTab {
  PERSONS = 'PERSONS',
  GROUPS = 'GROUPS'
}
