'use strict';

/* @ngInject */
export default class ExternalPersonsController {
  constructor($rootScope, $scope, $log, $location, dataService, restService, errorService, Notification) {
    this.$log = $log;
    this.$log.debug('ExternalPersonsController started...');
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$location = $location;
    this.dataService = dataService;
    this.restService = restService;
    this.errorService = errorService;
    this.Notification = Notification;


    //Load person on startup
    this.loadPerson();
  }


  loadPerson() {
    this.$rootScope.isLoading = true;

    this.id = this.$location.search().id;
    this.auth = this.$location.search().auth;
    this.username = this.$location.search().username;

    if (angular.isUndefined(this.id) || angular.isUndefined(this.auth) || angular.isUndefined(this.username)) {
      //Invalid values
      this.errorService.notify(this.errorService.ERROR_IDS.INVALID_ARGUMENTS);
      return;
    }

    this.restService.loadPerson(this.username, this.id, this.auth,
      (response) => {
        this.person = response.data.person;
        this.lstOfFields = response.data.lstOfFields;
        this.$rootScope.isLoading = false;
        this.isLoaded = true;
      },
      () => {
        //Error occured
        this.$rootScope.isLoading = false;
        this.isLoaded = true;
        this.isError = true;
      });
  };



  /**
    Save an existing person
  */
  savePerson() {
    this.$rootScope.isLoading = true;
    
    this.person.displayName = this.person.lastName + ' ' + this.person.firstName;
    
    this.restService.savePersonViaExternalAccess(this.username, this.auth, this.person,
      () => {
        this.$rootScope.isLoading = false;
        this.loadPerson();
        this.Notification.success({message: 'Daten erfolgreich aktualisiert!', title: 'Änderungen durchgeführt'});
      },
      () => {
        //Error occured
        this.$rootScope.isLoading = false;
      });
  };
}