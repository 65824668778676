'use strict';

import { IScope } from 'angular';

require('./alarmtext.editor.css');

export default class ContentEditableTextBaseFieldComponent {
  public require: string;
  public link: any;
  public restrict: string;
  private lstOfKnownParameters: Map<string, ParameterPlaceholder> = new Map<string, ParameterPlaceholder>();

  constructor() {
    this.restrict = 'AE';
    this.require = 'ngModel';
    this.init();
    this.link = (scope: IScope, elm, attrs, ctrl) => {
      // model -> view
      ctrl.$render = () => {
        elm.html(this.createFormattedHTML(ctrl.$viewValue));
      };
    };
  }

  createFormattedHTML(input: string): string {
    const words = input.split('&');
    let result = '';
    let formatting = false;
    for (var i = 0; i < words.length; i++) {
      const word = words[i];
      if (i === 0) {
        // First element, no formatting
        result += word;
      } else {
        formatting = !formatting;
        if (formatting) {

          if (this.lstOfKnownParameters.has(word)) {
            // This is a known parameter
            const explanation = this.lstOfKnownParameters.get(word);

            result += '<span class="known param" title="' + explanation.parameter + '">' + explanation.example + '</span>';
          } else {
            if (isNaN(Number(word))) {
              // Now a known parameter
              result += '<span class="unknown param">&' + word + '&<i class="fas fa-exclamation-triangle param-error" title="' + 'Unbekannter Platzhalter' + '"></i></span>';
            } else {
              // Is a number > custom alarm text
              result += '<span class="custom param">&' + word + '&</span>';
            }
          }

        } else {
          result += word;
        }
      }
    }
    return result;
  }


  init(): void {
    this.lstOfKnownParameters.set('SA', { parameter: 'sammel', example: 'Einheit A;Einheit B' });
    this.lstOfKnownParameters.set('EA', { parameter: 'express', example: 'Einheit A;Einheit B' });
    this.lstOfKnownParameters.set('FILE', { parameter: 'FILE', example: 'Dies ist ein Test aus Datei' });
    this.lstOfKnownParameters.set('FILE1', { parameter: 'FILE1', example: 'Dies ist ein Test aus Datei 1' });
    this.lstOfKnownParameters.set('FILE2', { parameter: 'FILE2', example: 'Dies ist ein Test aus Datei 2' });
    this.lstOfKnownParameters.set('FILE3', { parameter: 'FILE3', example: 'Dies ist ein Test aus Datei 3' });
    this.lstOfKnownParameters.set('SA_TEXT', { parameter: 'SA_TEXT', example: 'Dies ist ein Test' });
    this.lstOfKnownParameters.set('ANZ_AUSFALL', { parameter: 'ANZ_AUSFALL', example: '1' });
    this.lstOfKnownParameters.set('ZIEL', { parameter: 'location_dest', example: 'Musterstraße 1, 84575 Augsburg' });
    this.lstOfKnownParameters.set('STRASSE', { parameter: 'street', example: 'Musterstraße' });
    this.lstOfKnownParameters.set('STADT', { parameter: 'city', example: 'Augsburg' });
    this.lstOfKnownParameters.set('ORTSTEIL', { parameter: 'city_abbr', example: 'Innenstadt' });
    this.lstOfKnownParameters.set('PLZ', { parameter: 'postalCode', example: '84575' });
    this.lstOfKnownParameters.set('HSNR', { parameter: 'house', example: '1' });
    this.lstOfKnownParameters.set('LAT', { parameter: 'lat', example: '48.366284' });
    this.lstOfKnownParameters.set('LNG', { parameter: 'lng', example: '10.885944' });
    this.lstOfKnownParameters.set('TVP', { parameter: 'tvp', example: 'TVPRBBBBBBBBRLLLLLLLLT' });
    this.lstOfKnownParameters.set('TEXT', { parameter: 'pluginmessage', example: 'Dies ist ein Test' });
    this.lstOfKnownParameters.set('GPS', { parameter: 'gps', example: 'GPSN12516064E34377650' });
    this.lstOfKnownParameters.set('KARTENLINK', { parameter: 'location_URL', example: 'https://goo.gl/maps/JpNyxw4Po79PgKtP6' });
    this.lstOfKnownParameters.set('STICHW', { parameter: 'keyword', example: '🔥 Brand Brandmeldeanlage' });
    this.lstOfKnownParameters.set('STICHW_ID', { parameter: 'keyword_ident', example: 'B1716' });
    this.lstOfKnownParameters.set('STICHW_BESCHR', { parameter: 'keyword_description', example: 'BMA' });
    this.lstOfKnownParameters.set('STICHW_ZUSATZ', { parameter: 'keyword_misc', example: '🔥' });
    this.lstOfKnownParameters.set('STICHW_KATEGORIE', { parameter: 'keyword_category', example: 'Brandmeldeanlage' });
    this.lstOfKnownParameters.set('ZIEL_ZUSATZ', { parameter: 'location_additional', example: 'Eingang ist hinter dem Gebäude' });
    this.lstOfKnownParameters.set('NAHE_OBJEKTE', { parameter: 'nearObjects', example: 'Fachoberschule, Grundschule' });
    this.lstOfKnownParameters.set('GEBÄUDE', { parameter: 'building', example: 'Musterobjekt' });
    this.lstOfKnownParameters.set('SICHERHEITSHINWEISE', { parameter: 'building_hints', example: '1' });
    this.lstOfKnownParameters.set('GEFAHREN', { parameter: 'building_danger', example: '4' });
    this.lstOfKnownParameters.set('EINHEIT', { parameter: 'unit', example: 'Beispiel Einheit' });
    this.lstOfKnownParameters.set('UHR_LANG', { parameter: 'clock_long', example: '12:45:1,' });
    this.lstOfKnownParameters.set('MITTEILER', { parameter: 'caller', example: 'Herr Meier' });
    this.lstOfKnownParameters.set('MITTEILER_KONTAKT', { parameter: 'caller_contact', example: '0176/12345678' });
    this.lstOfKnownParameters.set('EINSATZMITTEL', { parameter: 'Spezieller Parameter', example: 'LF 40/1\nELW 12/1' });
    this.lstOfKnownParameters.set('U', { parameter: 'Aktuelle Uhrzeit', example: '12:45' });
    this.lstOfKnownParameters.set('D', { parameter: 'Aktuelles Datum', example: '24.12.2020' });
    this.lstOfKnownParameters.set('ÄNDERUNGEN', { parameter: 'changeReason', example: 'B3 -> B4' });
    this.lstOfKnownParameters.set('PAUSE_LANG', { parameter: 'Pause (lang)', example: 'PAUSE (lang)' });
    this.lstOfKnownParameters.set('PAUSE_KURZ', { parameter: 'Pause (kurz)', example: 'PAUSE (kurz)' });
    this.lstOfKnownParameters.set('EXTERNE_ID', { parameter: 'externalId', example: 'ID 123-232-1' });
    this.lstOfKnownParameters.set('TRANSPORT_ZIEL', { parameter: 'target_dest', example: 'An der Uni-Klinik 1, Augsburg' });
    this.lstOfKnownParameters.set('W3W', { parameter: 'what3words', example: '///gebäude.kaffee.quadrat' });
    this.lstOfKnownParameters.set('FEEDBACK_URL', { parameter: 'id_url', example: 'https://link-zu-den-rückmeldungen' });
    this.lstOfKnownParameters.set('EINSATZMITTEL_DISPO', {parameter: 'Einsatzmittel mit Dispositions-Info', example:'Fahrzeug A\nFahrzeug B (Pressluftatmer)\nFahrzeug C'})
    this.lstOfKnownParameters.set('TAG', { parameter: 'Tag', example: 'Mo' });
    this.lstOfKnownParameters.set('TAG_LANG', { parameter: 'Wochentag', example: 'Montag' });


    this.lstOfKnownParameters.set('OBJEKT_BMA', { parameter: 'Aus Objekt', example: 'BMA: Hinter dem Eingang' });
    this.lstOfKnownParameters.set('OBJEKT_BMA_MELDER_NR', { parameter: 'Aus Objekt', example: '12548' });
    this.lstOfKnownParameters.set('OBJEKT_BMA_BLITZLEUCHTE', { parameter: 'Aus Objekt', example: 'Ja' });
    this.lstOfKnownParameters.set('OBJEKT_FUNK', { parameter: 'Aus Objekt', example: 'TMO 1223' });
    this.lstOfKnownParameters.set('OBJEKT_KONTAKTE', { parameter: 'Aus Objekt', example: '- Max Mustermann' });
    this.lstOfKnownParameters.set('OBJEKT_ZUFAHRTEN', { parameter: 'Aus Objekt', example: 'Musterstraße: Über Ackermannstraße' });
    this.lstOfKnownParameters.set('OBJEKT_GEFAHREN', { parameter: 'Aus Objekt', example: 'Gefahren' });
    this.lstOfKnownParameters.set('OBJEKT_BEREITSTELLUNG', { parameter: 'Aus Objekt', example: 'Bereitstellungsraum' });
    this.lstOfKnownParameters.set('OBJEKT_SAMMELPLÄTZE', { parameter: 'Aus Objekt', example: 'Sammelplätze' });
    this.lstOfKnownParameters.set('OBJEKT_ZUGÄNGE', { parameter: 'Aus Objekt', example: 'Zugänge' });
    this.lstOfKnownParameters.set('OBJEKT_VERFÜGUNGSRAUM', { parameter: 'Aus Objekt', example: 'Verfügungsraum' });
    this.lstOfKnownParameters.set('OBJEKT_BETREUUNGSSTELLE', { parameter: 'Aus Objekt', example: 'Betreuungsstelle' });
    this.lstOfKnownParameters.set('OBJEKT_ANLAUFSTELLE', { parameter: 'Aus Objekt', example: 'Anlaufstelle' });
    this.lstOfKnownParameters.set('OBJEKT_ANLEITERSTELLE', { parameter: 'Aus Objekt', example: 'Anleiterstelle' });
    this.lstOfKnownParameters.set('OBJEKT_LADEZONE', { parameter: 'Aus Objekt', example: 'Ladezone' });
    this.lstOfKnownParameters.set('OBJEKT_PATIENTENABLAGE', { parameter: 'Aus Objekt', example: 'Patientenablage' });
    this.lstOfKnownParameters.set('OBJEKT_RMHP', { parameter: 'Aus Objekt', example: 'RMHP' });
    this.lstOfKnownParameters.set('OBJEKT_BEHANDLUNGSPLATZ', { parameter: 'Aus Objekt', example: 'Behandlungsplatz' });


  }
}

interface ParameterPlaceholder {
  parameter: string,
  example?: string
}