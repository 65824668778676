'use strict';
import angular = require('angular');
import { UserAccount } from '../../../../data/account.data';
import { DuplicateGlobalSettings, ELogLevel, MapsUsage, MiscSettings } from '../../../../data/admin.data';
import { WebSocketServerModeEvent } from '../../../../data/socket.data';
import PrivilegeService from '../../../../services/privilege.service';
import RestService from '../../../../services/rest.service';
require('./admin.settings.component.css');

export default class AdminSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./admin.settings.component.html');
    this.scope = {};
    this.controller = AdminSettingsComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}

/* @ngInject */
class AdminSettingsComponentController {
  public $scope: angular.IScope;
  public $rootScope: angular.IRootScopeService;
  public logLevels: ELogLevel[];
  public isLoadingMapsUsage: boolean;
  public isChangingLogLevel: boolean;
  public isChangingRevision: boolean;
  public usageOk: boolean;
  public isCompacting: boolean;
  public mapsUsage: MapsUsage;
  public dataService: any;
  public $state: any;
  public $log: angular.ILogService;
  public $translate: any;
  public Notification: any;
  public restService: RestService;
  public listeners: any;
  public account: UserAccount;

  public duplicateSettings: DuplicateGlobalSettings;
  public miscSettings: MiscSettings;
  public isLoadingDuplicate: boolean = false;
  public isLoadingMisc: boolean = false;

  public availableSpeeds = [];
  public mode: AdminSettingsMode = AdminSettingsMode.OVERVIEW;

  private isRestarting: boolean = false;

  constructor($scope: angular.IScope, $rootScope: angular.IRootScopeService, dataService, $state, $log, $translate,
    Notification, restService: RestService, private $uibModal: any,
    public privilegeService: PrivilegeService) {

    for (let i = -10; i <= 10; i++) {
      this.availableSpeeds.push(i);
    }

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.logLevels = [ELogLevel.TRACE, ELogLevel.DEBUG, ELogLevel.INFO, ELogLevel.WARN, ELogLevel.ERROR];
    this.isLoadingMapsUsage = false;
    this.isChangingLogLevel = false;
    this.usageOk = true;
    this.isCompacting = false;
    this.mapsUsage = {
      limit: 0,
      used: 0,
      remaining: 0
    } as MapsUsage;
    this.dataService = dataService;
    this.$state = $state;
    this.$log = $log;
    this.$translate = $translate;
    this.Notification = Notification;
    this.restService = restService;
    this.listeners = [];
    this.initListeners();

    if (dataService.hasAccount()) {
      this.init();
      this.account = dataService.account;
    }

  };

  init() {
    this.loadMapsApiUsage();
    this.loadDuplicateSettings();
    this.loadMiscSettings();
  }


  /**
   * Log off all active users by generating a new secure signing key
   */
  logOffAllUsers() {
    this.restService.logOffAllUsers(
      () => {
        this.dataService.logout();
        //Switch scope to login
        this.$state.go('main.login');
      },
      (err) => {
        this.$log.error('Could not log off');
        this.$log.error(err);
      });
  };

  /**
   * Compact storage of database
   */
  compactStorage() {
    this.isCompacting = true;
    this.restService.compactStorage(
      (response) => {
        this.isCompacting = false;

        this.$translate(['ADMIN.COMPACT_SUCCESSFULL_MESSAGE']).then((translations) => {
          this.Notification.success({
            message: response.data.message,
            title: translations['ADMIN.COMPACT_SUCCESSFULL_MESSAGE']
          });
        });

      }, (err) => {
        this.$log.error('Could not compact storage');
        this.$log.error(err);
        this.isCompacting = false;
      });
  };

  changeLogLevel(newLogLevel: ELogLevel) {
    this.isChangingLogLevel = true;
    this.restService.changeLogLevel(newLogLevel,
      (response) => {
        this.isChangingLogLevel = false;
        this.account.server.logLevel.levelStr = response.data.levelStr;
        this.account.server.logLevel.levelInt = response.data.levelInt;
        this.$translate(['ADMIN.LOG_CHANGED_MESSAGE', 'ADMIN.LOG_CHANGED_TITLE']).then((translations) => {
          this.Notification.success({
            message: translations['ADMIN.LOG_CHANGED_MESSAGE'],
            title: translations['ADMIN.LOG_CHANGED_TITLE']
          });
        });
      },
      (err) => {
        this.$log.error('Could not change log level');
        this.$log.error(err);
        this.isChangingLogLevel = false;
      });
  }

  // Load maps api usage
  loadMapsApiUsage() {
    this.isLoadingMapsUsage = true;
    this.restService.loadMapsUsage(
      (response) => {
        this.isLoadingMapsUsage = false;
        this.$log.info(response.data);
        this.mapsUsage = response.data;
        if (this.mapsUsage.remaining > 0) {
          this.usageOk = true;
        } else {
          this.usageOk = false;
        }
      },
      (err) => {
        this.isLoadingMapsUsage = false;
        this.$log.error('Could not load maps usage');
        this.$log.error(err);
        this.usageOk = false;
      });
  };

  initListeners() {
    //Wait for new account
    this.listeners.push(
      this.$rootScope.$on('new.account', () => {
        this.init();
        this.account = this.dataService.account;
      })
    );

    this.listeners.push(
      this.$rootScope.$on('server.mode', (event, data: WebSocketServerModeEvent) => {
        if (this.account) {
          this.account.server.mode = data.mode;
          this.$scope.$applyAsync();
        }
      })
    );

    this.listeners.push(this.$rootScope.$on('websocket.connection',
      (event, data) => {
        if(data.isConnected) {
          this.isRestarting = false;
          this.$scope.$apply();
        }
      }));

    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  loadDuplicateSettings() {
    this.isLoadingDuplicate = true;
    this.restService.loadDuplicateSettings()
      .then(settings => {
        this.duplicateSettings = settings;
      }).catch(err => {
        this.$log.error(err);
      }).finally(() => {
        this.isLoadingDuplicate = false;
        this.$scope.$applyAsync();
      });
  }

  saveDuplicateSettings() {
    this.isLoadingDuplicate = true;
    this.restService.saveDuplicateSettings(this.duplicateSettings)
      .then(settings => {
        this.duplicateSettings = settings;
      }).catch(err => {
        this.$log.error(err);
      }).finally(() => {
        this.isLoadingDuplicate = false;
        this.$scope.$applyAsync();
      });
  }

  loadMiscSettings() {
    this.isLoadingMisc = true;
    this.restService.loadMiscSettings()
      .then(settings => {
        this.miscSettings = settings;
      }).catch(err => {
        this.$log.error(err);
      }).finally(() => {
        this.isLoadingMisc = false;
        this.$scope.$applyAsync();
      });
  }

  saveMiscSettings() {
    this.isLoadingMisc = true;
    this.restService.saveMiscSettings(this.miscSettings)
      .then(settings => {
        this.miscSettings = settings;
      }).catch(err => {
        this.$log.error(err);
      }).finally(() => {
        this.isLoadingMisc = false;
        this.$scope.$applyAsync();
      });
  }

  restart() {
    let modalInstance = this.$uibModal.open({
      template: require('../../../modals/admin/confirm.restart.modal/confirm.restart.modal.html'),
      controller: 'ConfirmRestartModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {}
    })

    modalInstance.result.then((confirmed: boolean) => {
      if(confirmed) {
        this.isRestarting = true;
        this.$scope.$applyAsync();
        this.executeRestart();
      }
    })
  }

  executeRestart() {
    this.restService.restartFE2()
      .then(() => {
        this.$translate(['ADMIN.RESTART.NOTIFICATION_TITLE', 'ADMIN.RESTART.NOTIFICATION_MESSAGE']).then((translations) => {
          this.Notification.success({
            message: translations['ADMIN.RESTART.NOTIFICATION_MESSAGE'],
            title: translations['ADMIN.RESTART.NOTIFICATION_TITLE']
          });
        })
      })
      .catch(err => {
        this.$translate(['COMMON.UNKNOWN_ERROR', 'COMMON.ERROR_TITLE']).then((err) => {
          this.Notification.error({
            message: err['COMMON.UNKNOWN_ERROR'],
            title: err['COMMON.ERROR_TITLE']
          });
        });
      })
  }

}


enum AdminSettingsMode {
  OVERVIEW = 'OVERVIEW',
  PRIVACY = 'PRIVACY',
  LICENCE = 'LICENCE',
  ACTIONS = 'ACTIONS',
  DUPLICATE = 'DUPLICATE',
  MASSALERT = 'MASSALERT',
  EXPRESSALERT = 'EXPRESSALERT',
  VOICES = 'VOICES',
  MISC = 'MISC',
  KEYWORDS = 'KEYWORDS',
  WEBSERVER = 'WEBSERVER',
  MASTERSLAVE = 'MASTERSLAVE',
  MAILACCOUNT = 'MAILACCOUNT',
  OUTPUTPLUGINS = 'OUTPUTPLUGINS',
  REPLACEMENTS = 'REPLACEMENTS',
  LOGO = 'LOGO',
  OUTAGE = 'OUTAGE',
  FEEDBACK_GROUPS= 'FEEDBACK_GROUPS'
}