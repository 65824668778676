'use strict';

import angular = require("angular");
import {
  Person,
  PersonsAddressbookFullResponse,
  PersonsAddressbookResponse,
  PaginatedDatabasePersonProvisioningRepresentation
} from "../../../../data/person.data";
import { Provisioning, ProvisioningSimple } from "../../../../data/provisioning.data";
import RestService from "../../../../services/rest.service";
import { SortParams } from "../../../views/addressbook.view.component/addressbook.view.component";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";

require('./apager.component.css');

export default class ApagerComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E',
      this.scope = {},
    this.template = require('./apager.component.html');

    this.controller = ApagerController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//tableApager
/* @ngInject */
class ApagerController {
  public $scope: angular.IScope;
  public $rootScope: angular.IRootScopeService;
  public $log: angular.ILogService;
  public $uibModal: any;
  public restService: RestService;
  public dataService: any;
  public listeners: any;
  public addressbook: PaginatedDatabasePersonProvisioningRepresentation;
  public provisionings: ProvisioningSimple[] = [];

  public isLoading: boolean = false;
  public isFirstTimeLoading = true;
  private params: SortParams;
  public priv: PrivilegeService;
  public hasProvCreate:boolean=false;
  public hasProvDelete:boolean= false;
  public hasProvAssign:boolean= false;

  constructor($scope: angular.IScope, $rootScope: angular.IRootScopeService, $log: angular.ILogService, $uibModal, restService: RestService, dataService, privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.restService = restService;
    this.dataService = dataService;
    this.priv = privilegeService;

    this.listeners = [];

    this.params = {
      sortType: 'displayName', // set the default sort type
      sortReverse: false, // set the default sort order
      searchFilter: '', // set the default search/filter term
      currentPage: 0,
      totalElements: 0,
      pageSize: 20
    } as SortParams;

    this.$log.info('Init apager.component.ts...');

    this.initListeners();
  }

  initListeners() {
    this.listeners.push(this.$rootScope.$on('addressbook.view.tab', (event, source) => {
      if (source === 4) {

        this.hasProvCreate = this.priv.has(RolePrivilege.Addressbook_Provisioning_Create);
        this.hasProvDelete = this.priv.has(RolePrivilege.Addressbook_Provisioning_Delete);
        this.hasProvAssign = this.priv.has(RolePrivilege.Addressbook_Provisioning_Assign);
        // Trigger reload
        this.updateProvisioningProfiles();
        // Load persons
        this.pageChanged();
      }
    }));
    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  /**
   * Check all the persons aPager PRO status
   * @param {} person
   */
  checkStatusOfAll() {
    this.addressbook.content.forEach(person => {
      // Just emit event. apager.tablerow.component will handle it
      this.$rootScope.$emit('person.' + person.id + '.checkApagerStatus');
    });
  };

  /**
   * Load provisioning from server
   */
  updateProvisioningProfiles() {
    this.$log.info('Loading provisioning configuration...');


    //Load provisionings
    this.isLoading = true;
    this.dataService.getProvisionings(true, (data: ProvisioningSimple[]) => {
      this.isLoading = false;
      this.$log.debug(data);
      this.provisionings = data;
      this.$rootScope.$emit('provision.loaded');
    }, (err) => {
      this.provisionings = [];
      this.isLoading = false;
      this.$log.error(err);
    });
  };


  /**
   * Change the page
   * @returns A promise which resolves after changing the page
   */
  pageChanged() {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.restService.loadPersonsProvisionRep(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1, this.params.searchFilter, this.params.sortReverse ? 'DESC' : 'ASC', this.params.pageSize, 'aPagerPro',true)
        .then((result: PaginatedDatabasePersonProvisioningRepresentation) => {
          this.addressbook = result;
          this.params.totalElements = this.addressbook.totalElements;
          this.params.totalPages = this.addressbook.totalPages;
        }).finally(() => {
          this.isLoading = false;
          this.$scope.$applyAsync();
          this.isFirstTimeLoading = false;
          resolve();
        });
    });
  }

  /**
   * Assign provisioning to users
   * @param {*Provisioning configuration} prov
   */
  assignProvisioning(provisioningProfile: ProvisioningSimple) {
    this.$uibModal.open({
      template: require('../../../modals/addressbook/assign.provisioning.modal/assign.provisioning.modal.html'),
      controller: 'AssignProvisioningModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'sm',
      resolve: {
        provisioningProfile: () => {
          return provisioningProfile;
        }
      }
    });
  };

  /**
   * Create new provisioning
   */
  createProvisioning() {
    this.$log.info('Creating provisioning configuration...');
    this.isLoading = true;
    this.dataService.createProvisioning((data: Provisioning) => {
      this.isLoading = false;

      this.editProvisioning(data);

      this.$log.debug(data);
    }, (err) => {
      this.isLoading = false;
      this.$log.error(err);
    });
  };

  /**
   * Delete new provisioning
   */
  deleteProvisioning(prov: ProvisioningSimple) {
    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.$log.info('Deleting provisioning configuration...');
            this.isLoading = true;
            this.dataService.deleteProvisioning(prov, (data) => {
              this.isLoading = false;
              this.$log.debug(data);
              // Submit event
              this.$rootScope.$emit('provision.deleted', prov);
            }, (err) => {
              this.isLoading = false;
              this.$log.error(err);
            });
          }
        },
        additionalText: () => {
          return;
        }
      }
    });
  };

  /**
   * Publish updated provisioning to everybody
   */
  updateProvisioning(prov: Provisioning) {
    this.$log.info('Updating provisioning for all with old provisioing...');

    // TODO update????? why in ui still?

    /*this.apagers.forEach((person: Person) => {
      if (angular.isDefined(person.apagerPersonData)) {
        var personProvId = person.apagerPersonData.provisioningId;
        if (prov.id === personProvId) {
          if (prov.version !== person.apagerPersonData.version) {
            this.updatePerson(person, prov);
          } else {
            this.$log.debug('Already up to date: ' + person.displayName);
          }
        }
      }
    });
    */
  };

  /**
   * Provision existing person with new version of provisioning
   * @param {*} person
   */
  updatePerson(person: Person, prov: ProvisioningSimple) {
    // Emit event. apager.tablerow.component will handle it
    this.$rootScope.$emit('person.' + person.personID + '.provision', prov);
  };

  /**
   * Duplicate provision
   * @param prov provisioning
   */
  duplicateProvisioning(prov: Provisioning): void {
    this.isLoading = true;
    this.dataService.duplicateProvisioning(prov, (data: Provisioning) => {
      this.isLoading = false;
      this.editProvisioning(data);
    }, (response) => {
      this.$log.error(response);
    });
  }

  /**
   * Edit provisioning
   */
  editProvisioning(prov: ProvisioningSimple) {
    const modalInstance = this.$uibModal.open({
      template: require('../../../modals/addressbook/provisioning.modal/provisioning.modal.html'),
      controller: 'ProvisioningInstanceModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        provisioning: () => {
          return prov;
        }
      }
    });

    modalInstance.result.then(() => {
      // Trigger refresh after closing modal
      this.updateProvisioningProfiles();
    }, () => {
      // Ignore
    });
  };



}