'use strict';

import { IRootScopeService, IScope } from "angular";
import { CustomMapLayer, ECustomMapLayerType } from "../../../../../data/customMapLayer.data";
import RestService from "../../../../../services/rest.service";

require('./admin.map.component.css');

export default class AdminMapSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.map.component.html');
    this.scope = {
    };
    this.controller = AdminMapComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}

/* @ngInject */
class AdminMapComponentController {
  private isLoading: boolean = false;
  private restService: RestService;
  private $scope: IScope;
  private settings: CustomMapLayer;


  constructor($scope: IScope, $rootScope: IRootScopeService,
    restService: RestService,
    private dataService
  ) {
    this.restService = restService;
    this.$scope = $scope;
    this.load();
  }


  /**
   * Load map settings
   */
  load() {
    this.settings = this.dataService.getAccount().customMapLayer;
    if (!this.settings) {
      this.settings = {
        type: ECustomMapLayerType.TILE,
        name: "",
        url: "",
        wmsParameters: {
          layers: "",
          styles: "",
          format: "",
          version: ""
        }
      };
    }
  }

  /**
   * Save map settings
   */
  save() {
    this.isLoading = true;
    this.dataService.getAccount().customMapLayer = this.settings;
    this.restService.saveCustomMapLayer(this.settings).then(response => {
      this.settings = response;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  private setTypeTile(): void {
    this.setType(ECustomMapLayerType.TILE)
  }

  private setTypeWms(): void {
    this.setType(ECustomMapLayerType.WMS)
  }

  private setType(type: ECustomMapLayerType): void {
    this.settings.type = type;
    if (type === ECustomMapLayerType.TILE) return;
    if (!this.settings.wmsParameters) {
      this.settings.wmsParameters = {
        layers: "",
        styles: "",
        format: "",
        version: ""
      }
    }
  }


}

