'use strict';

import * as L from "leaflet";

/* @ngInject */
export default class ChooseOnMapController {

  constructor($uibModalInstance, $scope, $document, mapService, coords, okFunction) {
    this.$uibModalInstance = $uibModalInstance;
    this.$scope = $scope;
    this.mapService = mapService;
    this.coords = coords;
    this.okFunction = okFunction;
    this.listeners = [];
    this.isStatic = false;

    //Init map
    this.marker;
    this.coords = coords;

    if (angular.isUndefined(this.coords.lat)) {
      this.coords = {
        lat: settings.lat,
        lng: settings.lng
      }
    } else {
      // If accuracy is present, this is a static map
      this.isStatic = coords.accuracy !== undefined;
    }

    $document.ready(() => {
      this.initMap();
    });
  }


  initMap() {
    L.Icon.Default.imagePath = '/img/static';
    this.map = L.map('mapid').setView([this.coords.lat, this.coords.lng], 13);
    this.map.attributionControl.setPrefix('<a style="color:black !important" href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a>');
    
    let layers = this.mapService.getBaseLayers();

    L.control.layers(layers).addTo(this.map);

    let selectedLayer = this.mapService.getSelectedLayer();
    if(selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(this.map);

    let mapService = this.mapService;
    this.map.on('baselayerchange', function (e) {
      mapService.saveLayer(e.name);
    });

    this.marker = L.marker(L.latLng(this.coords.lat, this.coords.lng), { draggable: !this.isStatic }).addTo(this.map);

    if (!this.isStatic) {
      this.map.on('click', (ev) => {
        this.markerMovedEvent(ev.latlng);
      });
      this.marker.on('dragend', (e) => {
        this.markerMovedEvent(e.target.getLatLng());
      });
    }
  }

  /**
   * Should be triggered, if marker should be moved
   * @param latLng 
   */
   markerMovedEvent(latLng) {
    this.marker.setLatLng(latLng);
    this.coords.lat = latLng.lat;
    this.coords.lng = latLng.lng;
    this.$scope.$applyAsync();
  }


  ok() {
    if (this.name == '') {
      return;
    }
    this.$uibModalInstance.close();
    this.okFunction(this.coords);
  }
}
