'use strict';

import { IScope } from "angular";
import { isIP } from 'is-ip';
import { MailAccountSettings, MasterSlaveSettings } from "../../../../../data/admin.data";
import { EServerMode } from "../../../../../data/socket.data";
import RestService from "../../../../../services/rest.service";

require('./admin.mailaccount.component.css');

export default class AdminMailAccountSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.mailaccount.component.html');
    this.scope = {
    };
    this.controller = AdminMailAccountComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AdminMailAccountComponentController {
  public isLoading: boolean = false;
  private restService: RestService;
  private $scope: IScope;
  private settings: MailAccountSettings;
  private receiversToText: string;

  constructor($scope: IScope, restService: RestService) {
    this.restService = restService;
    this.$scope = $scope;
    this.load();
  }


  /**
   * Load map settings
   */
  load() {
    this.isLoading = true;
    this.restService.loadMailAccountSettings().then(settings => {
      this.settings = settings;
      this.receiversToText = this.settings.receiver.join('\n');
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  /**
   * Save map settings
   */
  save() {
    this.isLoading = true;
    this.settings.receiver = this.receiversToText.split('\n')
    this.restService.saveMailAccountSettings(this.settings).then(response => {
      this.settings = response;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }
}
