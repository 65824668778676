'use strict';


/* @ngInject */
export default class ExternalViewController {
  constructor(restService) {
    this.restService = restService;
  }

  getHeader() {
    return this.restService.getStaticImageUrl('header.jpg');
  }
}
