'use strict';

import { ILocationService, ILogService, IRootScopeService, IScope } from "angular";
import { UpcomingEvents } from "../../../../data/event.data";
import { EWidgetState, WidgetData } from "../../../../data/widget.data";
import CalendarService from "../../../../services/calendar.service";

require('./checkbox.css');

export default class FancyCheckboxComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;
  constructor() {
    this.restrict = 'EA'
    this.scope = {
      translation: '=',
      data: '=',
      property: '=',
      deactive: '=',
      link: '='
    }
    this.template = require('./checkbox.html');

    this.controller = FancyCheckboxComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class FancyCheckboxComponentController {

  private translation: string;
  private property: string;
  private data: any;
  private deactive: boolean;
  private link: string;


  toggle() {
    if (this.deactive) {
      return;
    }
    this.data[this.property] = !this.data[this.property];
  }

  help() {
    window.open(this.link, '_blank').focus();
  }

}
