'use strict';

require('./external.registration.view.component.css');

/* @ngInject */
export default class ExternalRegistrationController {
  constructor($rootScope, $scope, $log, $location, restService) {
    this.$log = $log;
    this.$log.debug('ExternalRegistrationController started...');
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.restService = restService;
    this.$location = $location;

    this.authProvided = true;
    this.registered = false;
    this.sending = false;
    this.status = 200;
    this.dsgvoAccepted = false;

    // Data
    this.request = {
      mobile: '',
      email: '',
      firstname: '',
      lastname: '',
      note: '',
      apager: '',
      groups: [],
      functions: [],
      alarmGroups: []
    };


    this.load();
  }

  /**
   * Get an static image
   */
  getStaticImage(imageName) {
    return this.restService.getStaticImageUrl(imageName);
  }


  load() {
    this.auth = this.$location.search().auth;

    if (angular.isUndefined(this.auth)) {
      //Invalid values
      this.authProvided = false;
      return;
    }

    this.$rootScope.isLoading = true;
    this.restService.getSelfRegistration(this.auth,
      (response) => {
        this.data = response.data;

        this.$rootScope.isLoading = false;
        this.$log.debug(response.data);
      },
      (err, response) => {
        this.status = response.status;
        //Error occured
        this.$rootScope.isLoading = false;
        this.isError = true;
      });
  };


  /**
   * Select or deselect online service function
   * @param {function} f 
   */
  selectFunction(f) {
    var index = this.request.functions.indexOf(f);
    if (index !== -1) {
      this.request.functions.splice(index, 1);
    } else {
      this.request.functions.push(f);
    }
  }

  /**
   * If online service function is selected or not
   * @param {function} f 
   */
  isFunctionSelected(f) {
    var index = this.request.functions.indexOf(f);
    return index !== -1;
  }


  /**
   * Select or deselect online service group
   * @param {function} f 
   */
  selectGroup(f) {
    var index = this.request.groups.indexOf(f);
    if (index !== -1) {
      this.request.groups.splice(index, 1);
    } else {
      this.request.groups.push(f);
    }
  }

  /**
   * If online service group is selected or not
   * @param {function} f 
   */
  isGroupSelected(f) {
    var index = this.request.groups.indexOf(f);
    return index !== -1;
  }

  /**
   * Select or deselect alarm group
   * @param {function} f 
   */
  selectAlarmGroup(f) {
    var index = this.request.alarmGroups.indexOf(f.id);
    if (index !== -1) {
      this.request.alarmGroups.splice(index, 1);
    } else {
      this.request.alarmGroups.push(f.id);
    }
  }

  /**
   * If alarm group is selected or not
   * @param {function} f 
   */
  isAlarmGroupSelected(f) {
    var index = this.request.alarmGroups.indexOf(f.id);
    return index !== -1;
  }


  /**
    Save an existing person
  */
  register() {
    this.$rootScope.isLoading = true;
    this.sending = true;
    this.$log.info(this.request);
    this.restService.registerViaExternal(this.request, this.auth,
      () => {
        this.$rootScope.isLoading = false;
        this.sending = false;
        this.registered = true;
      },
      () => {
        //Error occured
        this.sending = false;
        this.$rootScope.isLoading = false;
      });
  };
}
