'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import { VehicleAssignedAlarm } from "../../../../data/alarm.data";
import { StatusEntryResponse } from "../../../../data/vehicles.data";

require('./alarm.assigned.row.entry.component.scss');

export default class AlarmAssignedRowEntryComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'A';
    this.template = require('./alarm.assigned.row.entry.component.html');
    this.scope = {
      alarm: '='
    };
    this.controller = AlarmAssignedRowEntryComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  };
};
class AlarmAssignedRowEntryComponentController {
  public alarm: VehicleAssignedAlarm;
  private $rootScope: IRootScopeService;
  private $log: ILogService;
  private $uibModal: any;
  private $scope: IScope;
  private listeners = [];

  constructor($rootScope: IRootScopeService, $log: ILogService, $scope: IScope, $uibModal: any) {
    this.$uibModal = $uibModal;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$log = $log;

    this.$scope.$watch('ctrl.alarm', (oldValue, newVehicle: VehicleAssignedAlarm) => {
      if (newVehicle && this.listeners.length === 0) {
        // Register for topic
        const changeEvent = 'status.change.' + this.alarm.vehicleId;
        this.$log.debug(`Register for change events of ${newVehicle.vehicleName} on ${changeEvent}`);
        this.listeners.push(this.$rootScope.$on(changeEvent, (event, data: StatusEntryResponse) => {
          this.alarm.status = data.status;
          this.alarm.statusChangedTimestamp = new Date(data.timestamp);
          this.alarm.statusColor = data.color;
          this.alarm.statusTextColor = data.textColor;
          this.$scope.$applyAsync();
        }));
      }
    });

    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });

  }

  openMap() {
    this.$uibModal.open({
      template: require('../../../modals/alarms/choose.on.map.modal/choose.on.map.modal.html'),
      controller: 'ChooseAlarmOnMapController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        coords: () => {
          return {
            lat: this.alarm.lat,
            lng: this.alarm.lng,
            accuracy: 10 // Fake accuracy to create a static map
          }
        },
        okFunction: () => {
          return (coords) => {
            // Nothing to do
          }
        }
      }
    });

  }
}
