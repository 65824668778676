'use strict';

import { ILogService, IRootScopeService } from "angular";
import {OrganisationLicenceSetAndAvailableData, UpdateOrganisationLicenseRequest, UserAdminContext} from "../data/admin.data";
import { SimpleInputPluginInstance } from "../data/input.data";
import RestService from "./rest.service";

/* @ngInject */
export default class AdminService {
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public restService: RestService;

  constructor($log: ILogService, $rootScope: IRootScopeService, restService: RestService) {
    this.$log = $log;
    this.$rootScope = $rootScope;
    this.restService = restService;

    $log.debug('Init AdminService');
  }

  createOrganisation(name: string, password:string, createOnlineService:boolean): Promise<boolean>{
    return this.restService.addOrganisation(name, password, createOnlineService);
  }

  /**
   * Get all current available users
   * @returns All users
   */
  getAllUsers(): Promise<UserAdminContext[]> {
    return this.restService.getAllUsersForAdmin();
  }

  reset2FA(user: UserAdminContext): Promise<boolean> {
    return this.restService.reset2FA(user);
  }

  deleteOrganisation(user: UserAdminContext, deleteOS: boolean):Promise<boolean>{
    return this.restService.deleteOrganisation(user.id, deleteOS);
  }


  /**
   * Update the state of an input plugin
   */
  updateInputState(inputPlugin, inputState, success, error) {
    this.restService.updateInputState(inputPlugin, inputState,
      (response) => {
        success(response)
      },
      (response) => {
        error(response)
      });
  }

  getNumberOfAlarms(success, error) {
    var start = new Date()
    var end = this.getEndTime(start);
    this.restService.getNumberOfCurrentAlarms(start, end, 'false', success, error);
  }


  getStateOfInputPluginsAsNumbers(success, error) {
    this.restService.getNumberOfActiveInputPlugins(success, error);
  }

  getNumberOfAvailableSMS(success, error) {
    this.restService.loadRemainingSMS(success, error);
  }

  getNumberOfAvailableMapRequests(success, error) {
    this.restService.loadMapsUsage(success, error)
  }

  getEndTime(start: Date) {
    var end = new Date(start.getTime());
    // Set end to +24 hours
    end.setHours(start.getHours() + 24);
    return end;
  }

  getOrganisationLicenceStats(organisationID:string): Promise<OrganisationLicenceSetAndAvailableData>{
    return this.restService.getOrganisationLicenceInfo(organisationID);
  }

  updateOrganisationLicence(updateOrganisationLicenceRequest:UpdateOrganisationLicenseRequest, userId:string): Promise<boolean>{
    return this.restService.updateOrganisationLicence(updateOrganisationLicenceRequest,userId);
  }

  updateOrganisation(organisationID:string, name:string):Promise<boolean> {
    return this.restService.updateOrganisation(organisationID, name);
  }

  loadIOEspaConfigString(id: string): Promise<String> {
    return this.restService.loadIOEspaConfigString(id);
  }

  deleteInputPluginById(id: string): Promise<boolean> {
    return this.restService.deleteInputPluginById(id);
  }

  getAllAvailableInputPlugins(): Promise<SimpleInputPluginInstance[]> {
    return this.restService.getAllAvailableInputs();
  }

  createInputPlugin(inputPlugin: SimpleInputPluginInstance, name: string): Promise<SimpleInputPluginInstance> {
    return this.restService.createInputPlugin(inputPlugin, name);
  }
}
