import {ILogService, IScope} from "angular";
import { Announcement, EAnnouncementType } from "../../../../data/announcement.data";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";

require('./edit.announcement.modal.css')

/* @ngInject */
export default class EditAnnouncementModalController {

    types: string[] = Object.keys(EAnnouncementType).filter((item) => {
        return isNaN(Number(item));
    });

    private startDate: Date;
    private endDate: Date;
    private isLoading: boolean;

  constructor(private $log: ILogService, private $scope: IScope, private $uibModalInstance, private $uibModal, private restService: RestService,
    private announcement: Announcement, public privilegeService: PrivilegeService)
        {
        this.startDate = new Date(announcement.startDate);
        this.endDate = new Date(announcement.endDate);
    }

    save() {
        this.isLoading = true;
        this.restService.saveAnnouncement(this.announcement).then(announcement => {
            this.announcement = announcement
            this.isLoading = false;
            this.$uibModalInstance.close()
        }, err => {
            this.$log.error(err);
            this.isLoading = false;
            this.$scope.$applyAsync()
        });
    }

    close() {
        this.$uibModalInstance.close();
    }

    delete() {
        this.$uibModal.open({
            template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
            controller: 'ConfirmDeleteModalController',
            controllerAs: 'ctrl',
            size: 'md',
            resolve: {
              okFunction: () => {
                return () => {
                  this.isLoading = true
                  this.restService.deleteAnnouncementById(this.announcement.id).then((res) => {
                      this.isLoading = false
                      this.close()
                  }, err => {
                      this.$log.error(err)
                      this.isLoading = false
                      this.$scope.$applyAsync()
                  })
                };
              },
              additionalText: () => {
                return;
              }
            }
          });
    }

    dateChanged() {
        if (this.startDate > this.endDate) {
            this.endDate = new Date(this.startDate);
          }
        this.announcement.startDate = this.startDate;
        this.announcement.endDate = this.endDate;
      }


}