import angular from 'angular';
import 'angular-ui-bootstrap'
import services from '../../../services'


import UserDashboardComponentController from './user.dashboard.component/user.dashboard.component';
import FancyCheckboxComponentController from './checkbox/checkbox';
import PreviewComponentController from './preview/preview';

export default angular.module('FE2.components.misc', ['ui.bootstrap', services])
  //.controller('MainController', MainController)
  .directive('userDashboard', () => new UserDashboardComponentController)
  .directive('fancyCheckbox', () => new FancyCheckboxComponentController)
  .directive('preview', () => new PreviewComponentController)
  .name;
