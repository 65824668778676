'use strict';

/* @ngInject */
export default class DashboardService {

  constructor($log, $rootScope, dataService, restService) {
    this.$log = $log;
    this.$rootScope = $rootScope;
    this.restService = restService;
    this.dataService = dataService;
    this.startMinutes = 0;
    this.startHour = 0;

    $log.debug('Init DashboardService');

    this.$rootScope.$on('new.account', (event, account) => {
      this.updateSettings(account.settings);
    });

    this.$rootScope.$on('new.settings', (event, settings) => {
      this.updateSettings(settings);
    });

    if (this.dataService.hasAccount()) {
      this.updateSettings(this.dataService.getAccount().settings);
    }

    // Init data
    this.messages = {};
  }

  updateSettings(userSettings) {
    this.$log.info('Updated user settings!');
    this.startMinutes = userSettings.shiftBookStartMinutes;
    this.startHour = userSettings.shiftBookStartHour;
    this.$log.info('Start Hours/Minutes: ' + this.startHour + '/' + this.startMinutes);
  }

  getStartTime() {
    var start = new Date();
    start = new Date(start.getFullYear(), start.getMonth(), start.getDate(), this.startHour, this.startMinutes, 0);
    return start;
  }

  getEndTime(start) {
    var end = new Date(start.getTime());
    // Set end to +24 hours
    end.setHours(start.getHours() + 24);
    return end;
  }


  /**
   * Get the current number of alarms
   */
  getNumberOfAlarms(success, error) {
    var start = this.getStartTime();
    var end = this.getEndTime(start);
    this.restService.getNumberOfCurrentAlarms(start, end, 'true', success, error);
  };

  /**
   * Get the current number of events
   */
  getNumberOfEvents(success, error) {
    var start = this.getStartTime();
    var end = this.getEndTime(start);
    this.restService.getNumberOfCurrentEvents(start, end, success, error);
  };

  /**
   * Get the events in current time frame
   * @param {} success
   * @param {*} error
   */
  getEventsInTimeFrame(success, error) {
    var start = this.getStartTime();
    var end = this.getEndTime(start);
    this.restService.getEventsInTimeFrame(start, end, (response) => {
      success(response);
    }, (errorResponse) => {
      error(errorResponse);
    });
  };

  /**
   * Get the current number of roadblocks
   */
  getNumberOfRoadblocks(success, error) {
    var start = this.getStartTime();
    var end = this.getEndTime(start);
    this.restService.getNumberOfCurrentRoadblocks(start, end, success, error);
  }

  /**
   * Get the current number of vehicle faults and vehicles in status 6
   */
  getNumberOfVehicleFaultsAndStatus6(success, error) {
    this.restService.getNumberOfVehicleFaultsAndStatus6(success, error);
  }


  /**
   * Save a custom field in database
   */
  updateCustomField(customField, success, error) {
    this.restService.updateCustomField(customField, success, error);
  }

  /**
   * Load all messages for specific category
   */
  getShiftbookMessages(categoryId, success, error) {
    this.restService.getShiftbookMessages(categoryId, (response) => {

      // Convert date string to date object
      response.data.forEach(message => {
        message.creationDate = new Date(message.creationDate);
      });

      this.$rootScope.$emit('shiftbook.messages.cnt.' + categoryId, response.data.length);
      this.messages[categoryId] = response.data;
      success(response.data);
    }, error);
  }

  /**
   * Add a new shiftbook message
   * @param {} message
   * @param {*} success
   * @param {*} error
   */
  addNewShiftbookMessage(message, success, error) {
    this.restService.addNewShiftbookMessage(message, (response) => {
      // Convert creation date
      response.data.creationDate = new Date(response.data.creationDate);
      this.messages[message.categoryId].push(response.data);
      this.$rootScope.$emit('shiftbook.messages.cnt.' + message.categoryId, this.messages[message.categoryId].length);
      success(response.data);
    }, error);
  };

  /**
   * Update an existing shiftbook message
   * @param {} message
   * @param {*} success
   * @param {*} error
   */
  updateShiftbookMessage(message, oldCategoryId, success, error) {
    this.restService.updateShiftbookMessage(message, (response) => {

      if (angular.isDefined(oldCategoryId) && message.categoryId !== oldCategoryId) {
        // We need to move message to new category
        // Remove from previous category
        this.messages[oldCategoryId].splice(this.messages[oldCategoryId].indexOf(message), 1);
        this.$rootScope.$emit('shiftbook.messages.cnt.' + oldCategoryId, this.messages[oldCategoryId].length);
        // Add to new category
        this.messages[message.categoryId].push(message);
        this.$rootScope.$emit('shiftbook.messages.cnt.' + message.categoryId, this.messages[message.categoryId].length);
      }

      success(response.data);
    }, error);
  };


  /**
   * Delete an existing shiftbook message
   * @param {} message
   * @param {*} success
   * @param {*} error
   */
  deleteShiftbookMessage(message, success, error) {
    this.restService.deleteShiftbookMessage(message, () => {
      var categoryId = message.categoryId;
      this.messages[categoryId].splice(this.messages[categoryId].indexOf(message), 1);
      this.$rootScope.$emit('shiftbook.messages.cnt.' + categoryId, this.messages[categoryId].length);
      success();
    }, error);
  };
};
