'use strict';
//objectBma
export default class ObjectBmaComponent {
  constructor() {
    this.restrict = 'A'
    this.template = require('./object.bma.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectBmaController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectBmaController {
  constructor($scope, $uibModal) {
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.hasBMA = false;
    this.selected = undefined;

   /**
     * Init data changed
     */
    this.$scope.$watch('ctrl.alarmObject', () => {
      if (angular.isDefined(this.alarmObject)) {
        this.hasBMA = angular.isDefined(this.alarmObject.bma) && this.alarmObject.bma !== null;

      }
    });



  }

  /**
   * Remove BMA
   */
  removeBma() {
    this.alarmObject.bma = undefined;
    this.alarmObject.hasBMA = false;
    this.hasBMA = false;
  };

  /**
   * Add BMA
   */
  addBma() {
    this.alarmObject.bma = {
      callerNbr: '',
      hasFlashLight: false,
      elevators: [],
      extinguishes: [],
      reasons: [],
      ventilations: [],
      directioncards: [],
      bmz: {
        coords: {
        },
        note: ''
      },
      fsd: {
        coords: {
        },
        note: '',
        pin: ''
      },
      fiz: {
        coords:{
        },
        note:''
      },
      spz: {
        coords:{
        },
        note:''
      },
      keyTube: {
        coords:{
        },
        note:''
      }
    };

    if (angular.isDefined(this.alarmObject.address) && angular.isDefined(this.alarmObject.address.coords)) {
      this.alarmObject.bma.bmz.coords.lat = this.alarmObject.address.coords.lat;
      this.alarmObject.bma.fsd.coords.lat = this.alarmObject.address.coords.lat;
      this.alarmObject.bma.bmz.coords.lng = this.alarmObject.address.coords.lng;
      this.alarmObject.bma.fsd.coords.lng = this.alarmObject.address.coords.lng;
      this.alarmObject.bma.fsd.coords.showOnMap = true;
      this.alarmObject.bma.bmz.coords.showOnMap = true;
      this.alarmObject.bma.fiz.coords.lat = this.alarmObject.address.coords.lat;
      this.alarmObject.bma.fiz.coords.lng = this.alarmObject.address.coords.lng;
      this.alarmObject.bma.fiz.coords.showOnMap = true;
      this.alarmObject.bma.keyTube.coords.lat = this.alarmObject.address.coords.lat;
      this.alarmObject.bma.keyTube.coords.lng = this.alarmObject.address.coords.lng;
      this.alarmObject.bma.keyTube.coords.showOnMap = true;
      this.alarmObject.bma.spz.coords.lat = this.alarmObject.address.coords.lat;
      this.alarmObject.bma.spz.coords.lng = this.alarmObject.address.coords.lng;
      this.alarmObject.bma.spz.coords.showOnMap = true;
    }
    this.alarmObject.bma.hasFlashLight= false;
    this.alarmObject.hasBMA = true;
    this.hasBMA = true;
  };

  /**
   * Show BMZ on map
   * @param {*} fsd
   */
  showBMZ(bmz) {
    this.$uibModal.open({
      template: require('./../../../modals/objects/choose.on.map.modal/choose.on.map.modal.html'),
      controller: 'ChooseOnMapCtrl',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        coords: () => {
          return bmz.coords;
        },
        okFunction: () => {
          return (coords) => {
            bmz.coords = coords;
          }
        }
      }
    });
  };

  /**
   * Show FSD on map
   * @param {*} fsd
   */
  showFSD(fsd) {
    this.$uibModal.open({
      template: require('./../../../modals/objects/choose.on.map.modal/choose.on.map.modal.html'),
      controller: 'ChooseOnMapCtrl',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        coords: () => {
          return fsd.coords;
        },
        okFunction: () => {
          return (coords) => {
            fsd.coords = coords;
          }
        }
      }
    });
  };

  /**
   * Show fiz on map
   * @param {*} fiz
   */
  showFiz(fiz) {
    // prevent undefined exception  on old alarm objects bmas
    if (angular.isUndefined(fiz.coords)){
      fiz.coords = {
          lat: this.alarmObject.address.coords.lat,
          lng: this.alarmObject.address.coords.lng,
          showOnMap: true
      };
    }

    this.$uibModal.open({
      template: require('./../../../modals/objects/choose.on.map.modal/choose.on.map.modal.html'),
      controller: 'ChooseOnMapCtrl',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        coords: () => {
          return fiz.coords;
        },
        okFunction: () => {
          return (coords) => {
            fiz.coords = coords;
          }
        }
      }
    });
  };

}
