'use strict';

import { ILogService, IScope } from "angular";
import {OrganisationLicenceSetAndAvailableData, User, UserAdminContext} from "../../../../data/admin.data";
import RestService from "../../../../services/rest.service";
import AdminService from "../../../../services/admin.service";
import * as angular from "angular";
import PrivilegeService from "../../../../services/privilege.service";
require('./user.modal.css');

/* @ngInject */
export default class UserModalController {
  public $scope: IScope;
  public $log: ILogService;
  public $uibModalInstance: any;
  public Notification: any;
  public $translate: any;
  public restService: RestService;
  public isLoading = true;
  public user: User;
  public mode: UserModalMode = UserModalMode.OVERALL;
  public adminService:AdminService;
  public licenceStats: OrganisationLicenceSetAndAvailableData;
  // flag if username is in edit mode
  public editNameActive:boolean= false;
  // flag if username is allowed
  public isValidUserName:boolean = true;
  // keep tabs if save process is active
  public isChangingUserName: boolean = false;
  // distinguish if username has changed for reload and save handling
  public hasOrganisationNameChanged:boolean = false;
  public needsUserListReload:boolean = false;
  public userNames= [];
  public okFunction:any;

  // Password reset
  public randomPassword: string;
  public editedUserName: string;
  public isChangingPassword = false;

  constructor($scope: IScope, $log: ILogService, $uibModalInstance, restService: RestService,
    userContext: UserAdminContext, $translate, Notification, adminService: AdminService, public privilegeService: PrivilegeService, userNames, okFunction) {
    this.$scope = $scope;
    this.$log = $log;
    this.Notification = Notification;
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.$translate = $translate;
    this.adminService = adminService;
    this.userNames = userNames;
    this.okFunction = okFunction;

    // Load user
    this.restService.loadUser(userContext.id).then(user => {
      this.user = user;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    })


  }

  resetFlagsToOriginalState(){
    this.editNameActive= false;
    this.isValidUserName = true;
    this.isChangingUserName = false;
    this.hasOrganisationNameChanged = false;
    this.editedUserName = '';

  }

  validateOrganisationName(){
    let user;
    if (angular.isDefined(this.userNames)) {
      user = this.userNames.find(userName => userName.name === this.editedUserName);
      if (angular.isUndefined(user)) {
        // name has changed and is valid
        this.isValidUserName = true;
        this.hasOrganisationNameChanged = true;

      } else {
        if (user.id !== this.user.id) {
          // name has changed, but name already exists
          this.isValidUserName = false;
          this.hasOrganisationNameChanged = true;
        } else {
          // username not changed but edit mode is active handled this way to disable save,
          // to prevent save error because name exists
          this.isValidUserName = true;
          this.hasOrganisationNameChanged = false;
        }
      }
    }
    if (this.editedUserName === '') {
      this.isValidUserName = false;
      this.hasOrganisationNameChanged = false;
    }

    if (this.isValidUserName && this.hasOrganisationNameChanged) {
      // Save
      this.updateOrganisationName();
    }

  }

  updateOrganisationName(){
    this.isChangingUserName = true;
    this.$scope.$applyAsync();
    this.adminService.updateOrganisation(this.user.id, this.editedUserName).then((success) => {
      this.isLoading = false;
      this.user.name = this.editedUserName;
      this.userNames.map(user => {
        if (user.id == this.user.id) {
          user.name = this.editedUserName;
        }
        return user;
      });
      this.needsUserListReload = true;
    }).finally(()=>{
      this.resetFlagsToOriginalState()
      this.$scope.$applyAsync();
    })
  }


  cancel() {
    this.okFunction(this.needsUserListReload);
    this.$uibModalInstance.close();
  }

  changeAppPw() {
    this.isChangingPassword = true;
    this.restService.changePasswordForUser(this.user.id, this.randomPassword).then(() => {
      this.$translate(['SETTINGS.PW_OK', 'SETTINGS.PW_CHANGED']).then((translations) => {
        this.Notification.success({
          message: translations['SETTINGS.PW_CHANGED'],
          title: translations['SETTINGS.PW_OK']
        });
      });
    }).finally(() => {
      this.isChangingPassword = false;
      this.$scope.$applyAsync();
    });
  }


  generatePassword() {
    this.randomPassword = Array(8).fill("23456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz").map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join('');
    return this.randomPassword;
  }

  ok() {
    this.isLoading = true;

  }

}

enum UserModalMode {
  OVERALL = 'OVERALL',
  PASSWORD = 'PASSWORD',
  VEHICLES = 'VEHICLES',
  OS = 'OS',
  ALARMOBJECTS = 'ALARMOBJECTS'
}