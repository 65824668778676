'use strict';

require('./close.alarm.modal.css');


import { ILogService, IScope } from "angular";
import { AlarmData } from "../../../../data/alarm.data";
import {VehicleSimple} from "../../../../data/vehicles.data";
import RestService from "../../../../services/rest.service";

/* @ngInject */
export default class CloseAlarmModalController {
  public $scope: IScope;
  public $uibModalInstance: any;
  public restService: RestService;
  public alarm: AlarmData;
  public okFunction: any;
  public $log: ILogService;
  public date: Date = new Date();
  public opened: boolean = false;
  public format: string = 'dd.MM.yy';
  public selectedVehicleIds: string[] = [];
  public vehicles: VehicleSimple[] = [];
  public showSpinner: boolean = false;
  public loadingVehicles: boolean = false;
  public dateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };

  constructor($scope: IScope, $uibModalInstance, restService: RestService, alarm: AlarmData, okFunction, $log: ILogService) {
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.alarm = alarm;
    this.okFunction = okFunction;
    this.$log = $log;

    this.updateVehicleList();
  }

  open() {
    this.opened = true;
  }

  isSelected(id: string) {
    return this.selectedVehicleIds.indexOf(id) !== -1;
  }

  select(id: string) {
    if (this.isSelected(id)) {
      this.selectedVehicleIds.splice(this.selectedVehicleIds.indexOf(id), 1);
    } else {
      this.selectedVehicleIds.push(id);
    }
  }

  cancel() {
    this.$uibModalInstance.close();
  }

  ok() {
    this.$uibModalInstance.close();
    this.okFunction(this.date, this.selectedVehicleIds);
  }

  toggleAll() {
    if (this.selectedVehicleIds.length === 0) {
      // Add all
      for (var i = 0; i < this.vehicles.length; i++) {
        this.selectedVehicleIds.push(this.vehicles[i].id);
      }
    } else {
      // Clear
      this.selectedVehicleIds = [];
    }
  }
  updateVehicleList() {
    this.loadingVehicles = true;
    this.restService.getVehiclesForAlarmClosingWithStatusChange(this.alarm.time, this.date.getTime(), (vehicles: VehicleSimple[]) => {
      this.vehicles = vehicles;

      if (this.alarm.hasVehicles) {
        // Select only vehicles in alarm
        this.alarm.vehicles.forEach(vehicle => {
          const filtered = this.vehicles.filter(v => v.id === vehicle.vehicleId);
          if (filtered.length > 0) {
            this.selectedVehicleIds.push(vehicle.vehicleId);
          }
        });
      } else {
        // Select all vehicles
        this.vehicles.forEach(vehicle => {
          this.selectedVehicleIds.push(vehicle.id);
        });

      }

      this.loadingVehicles = false;
    }, (response) => {
      //Error occured
      this.$log.error(response);
      this.loadingVehicles = false;
    });
  }
}