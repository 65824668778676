'use strict';

import { IScope } from "angular";
import angular = require("angular");
import { UserAccount } from "../../../../data/account.data";
import { AlarmTemplate } from "../../../../data/template.data";
import RestService from "../../../../services/rest.service";

require('./confirm.template.modal.css')

/* @ngInject */
export default class ConfirmTemplateModalController {
  public $scope: IScope;
  public $uibModalInstance: any;
  public $translate: any;
  public Notification: any;
  public restService: RestService;
  public template: AlarmTemplate;
  public account: UserAccount;
  public alarmText: string;
  public isLoading = false;

  constructor($scope: IScope, $uibModalInstance, $timeout: angular.ITimeoutService, $translate, Notification, dataService, restService: RestService, template: AlarmTemplate) {
    this.$scope = $scope
    this.$uibModalInstance = $uibModalInstance
    this.$translate = $translate
    this.Notification = Notification;
    this.restService = restService;
    this.template = template;
    this.account = dataService.getAccount();
    this.alarmText = this.template.message;

    $timeout(() => {
      var element = document.getElementById('template-alarm-text');
      if (element) {
        element.focus();
      }
    })

    if(template.noConfirm) {
      // send alarm immediately
      this.sendAlarm();
    }
  }

  cancel() {
    this.$uibModalInstance.close();
  }

  sendAlarm() {
    this.isLoading = true;

    //Gather all data
    var alarm = {} as any;
    var template = angular.copy(this.template);
    template.message = this.alarmText;
    alarm.data = template;


    //Units
    var units = '';
    for (var i = 0; i < template.units.length; i++) {
      units = units + template.units[i].code;
      if (i < template.units.length - 1) {
        units = units + ';';
      }
    }
    alarm.units = units;
    delete alarm.data.unitCodes;
    delete alarm.data.id;

    // Delete empty coordinates
    if (angular.isDefined(alarm.data.lat)) {
      if (alarm.data.lat === 0) {
        delete alarm.data.lat;
        delete alarm.data.lng;
      }
    }
    delete template.units;

    this.restService.sendAlarm(alarm, () => {
      this.isLoading = false;
      this.$uibModalInstance.close();
      //Show popup
      this.$translate(['ALARM.SUCCESS_MESSAGE', 'ALARM.SUCCESS_TITLE']).then((translations) => {
        this.Notification.success({
          message: translations['ALARM.SUCCESS_MESSAGE'],
          title: translations['ALARM.SUCCESS_TITLE']
        });
      });
    }, (errorResponse) => {
      //Error occured
      this.isLoading = false;
    });
  }
}