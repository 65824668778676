import { IRootScopeService, ILogService, IScope } from "angular";
import { AAO, AAOResponsePaginated } from "../../../../data/aao.data";
import { Announcement, AnnouncementResponsePaginated } from "../../../../data/announcement.data";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";
import { SortParams } from "../../../views/addressbook.view.component/addressbook.view.component";

require('./aao.component.css');

export default class AAOComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public require: any;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./aao.component.html');
    this.scope = {
    };
    this.controller = AAOComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AAOComponentController {
  public restService: RestService;
  public $log: ILogService;
  public $scope: IScope;
  public isLoading: boolean;

  public data: AAOResponsePaginated;
  public selectedAAO: AAO;
  public listeners = [];

  public params: SortParams = {
    currentPage: 0,
    pageSize: 25
  } as SortParams;


  constructor($log: ILogService, $scope: IScope, $rootScope: IRootScopeService, private $uibModal,
    restService: RestService, public privilegeService: PrivilegeService) {
    this.restService = restService;
    this.$log = $log;
    this.$scope = $scope;

    this.listeners.push($rootScope.$on('update.aao.list', () => {
      this.reload();
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      this.listeners.forEach((listener) => listener());
    });

    this.pageChanged();
  }

  selectAAO(aao: AAO) {
    this.selectedAAO = aao;
  }

  isSelected(aao: AAO) {
    if (!this.selectedAAO) return false;
    return this.selectedAAO.id === aao.id;
  }


  /**
   * Triggers an reload
   */
  reload() {
    this.selectedAAO = undefined;
    this.params.currentPage = 0;
    this.pageChanged();
  }

  /**
   * Load all AAOs
   */
  pageChanged(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.restService.loadAAOs(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1, this.params.pageSize).then((response: AAOResponsePaginated) => {
        this.data = response;
        this.data.number = this.data.number + 1
        if (!this.selectedAAO && this.data.aaoResponseElements.length > 0) {
          // Select first element by default
          this.selectAAO(this.data.aaoResponseElements[0]);
        }
      }).finally(() => {
        this.isLoading = false;
        this.$scope.$apply();
        resolve();
      });
    });

  };

  /**
   * Open modal and add new AAO
   */
  addAAO() {
    this.$uibModal.open({
      template: require('../../../modals/objects/add.object.modal/add.object.modal.html'),
      controller: 'AddAlarmObjectCtrl',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'sm',
      resolve: {
        okFunction: () => {
          return (name) => {
            this.isLoading = true;
            this.restService.addAAO(name).then((aao) => {
              this.isLoading = false;
              // Open new created
              this.data.aaoResponseElements.push(aao);
              this.selectAAO(aao);
            }, (response) => {
              //Error occured
              this.$log.error(response);
              this.isLoading = false;
            });
          }
        }
      }
    });
  }

}