'use strict';

/* @ngInject */
export default class AddUnitModalController {
  constructor($scope, $uibModalInstance, okFunction) {
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;

    this.name = '';
  }

  ok() {
    this.$uibModalInstance.close();
    this.okFunction(this.name);
  };

  cancel() {
    this.$uibModalInstance.close();
  };
}
