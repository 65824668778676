'use strict';

import { IRootScopeService, IScope } from "angular";
import HelperService from "../../../../services/helper.service";
import PrivilegeService from "../../../../services/privilege.service";

require('./objects.tablerow.component.css');
//objectsRow
export default class ObjectRowComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'A'
    this.template = require('./objects.tablerow.component.html');
    this.scope = {
      alarmObject: '=',
      index: '=',
      account: '='
    };
    this.controller = ObjectRowComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectRowComponentController {
  public $uibModal: any;
  public $rootScope: IRootScopeService;
  public helperService: HelperService;
  public $scope: IScope;
  public listeners = [];
  public icon: string;
  public alarmObject: any;

  constructor($scope: IScope, $rootScope: IRootScopeService, $uibModal, helperService: HelperService, public privilegeService: PrivilegeService) {
    this.$uibModal = $uibModal;
    this.$rootScope = $rootScope;
    this.helperService = helperService;
    this.$scope = $scope;

    this.$scope.$watch('ctrl.alarmObject.type', () => {
      // Type change
      this.icon = 'icon-' + this.helperService.getIconForObjectType(this.alarmObject.type).iconName + '-small';
    });

    this.$scope.$watch('ctrl.alarmObject', () => {
      this.listeners.push(this.$rootScope.$on(this.alarmObject.id + '.nbrOfNewFeedback', (event, nbrOfNewFeedback) => {
        // Nbr of feedback change
        this.alarmObject.nbrOfNewFeedback = nbrOfNewFeedback;
      }));
    });



    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }
}
