'use strict';

require('./rule.entry.component.css');

export default class AvailabilityRuleEntryComponent {
  constructor() {
    this.restrict = 'E',
      this.scope = {
        onlineservice: '=',
        rule: '=',
        calculate: '='
      }
    this.template = require('./rule.entry.component.html');

    this.controller = AvailabilityRuleEntryController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//availabilityRuleEntry
/* @ngInject */
class AvailabilityRuleEntryController {
  constructor($scope, $rootScope, $log, $uibModal, dataService) {

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.dataService = dataService;
    this.ruleIsValid = true;
    this.listener = undefined;
    this.status = {
      isWarning: false,
      isError: false,
      isInfo: false,
      isOk: false
    }
    this.valid = {};

    this.$scope.$watch('ctrl.onlineservice', (nVal) => {
      if (angular.isUndefined(nVal)) {
        return;
      }
      //Watch for changes
      this.updateRule();
    });

    this.$scope.$watch('ctrl.rule', (nVal) => {
      if (angular.isUndefined(nVal)) {
        return;
      }

      if (angular.isDefined(this.rule) && angular.isUndefined(this.listener)) {
        this.initListener();
      }

      //Watch for changes
      this.updateRule();
    });
  }

  //Register for rule change events
  initListener() {
    //Init 
    this.listener = this.$rootScope.$on('rule.updated.' + this.rule.id, (event, data) => {
      this.$log.debug('Rule changed - Rule Entry');
      this.rule = data;
      this.updateRule();
    });

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listener();
    });
  }

  /**
   * Check if rules are valid
   */
  updateRule() {
    if (this.calculate) {
      // Update UI flags

      this.status = {
        isWarning: !this.rule.valid && this.rule.rule.severity === 'WARNING',
        isError: !this.rule.valid && this.rule.rule.severity === 'CRITICAL',
        isInfo: !this.rule.valid && this.rule.rule.severity === 'INFO',
        isOk: this.rule.valid,
        isCalculated: true
      }

    } else {
      // No need for calculation
      this.status = {
        isWarning: false,
        isError: false,
        isInfo: false,
        isOk: false,
        isCalculated: false
      }
    }
  }
}