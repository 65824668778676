'use strict';

/* @ngInject */
export default class AccountService {

  constructor($log, $rootScope, dataService) {
    $log.debug('Init AccountService');
    this.dataService = dataService;
    this.init();

    $rootScope.$on('new.account', () => {
      // Init controller, because we have a new account
      this.init();
    });
  }

  init() {
    if (this.dataService.hasAccount()) {
      this.account = this.dataService.getAccount();
    }
  }

  isFeatureEnabled(feature) {
    //try re-init if account empty
    if (!this.account) {
        return false;
    }

    return this.account.features.indexOf(feature) !== -1;
  }

}
