export interface WebSocketData {
    type: EWebSocketType,
    json: string;
}

export interface WebSocketLogin {
    jwt: string
}

export enum EWebSocketType {
    ALARM = 'ALARM',
    SERVER_STATE = 'SERVER_STATE',
    STATUS = 'STATUS',
    RELAIS = 'RELAIS',
    LOGIN = 'LOGIN',
    INPUT = 'INPUT',
    AMOBILE_CHANGED = 'AMOBILE_CHANGED',
    SERVER_MODE_STATE = 'SERVER_MODE_STATE',
    META_INFO_UPDATED = 'META_INFO_UPDATED',
    VEHICLE_ASSIGNED_TO_ALARM = 'VEHICLE_ASSIGNED_TO_ALARM',
    VEHICLE_REMOVED_FROM_ALARM = 'VEHICLE_REMOVED_FROM_ALARM'
}

export interface WebSocketServerStateEvent {
    state: EServerState
}


export interface WebSocketServerModeEvent {
    mode: EServerMode
}

export enum EServerState {
    WORKING = 'WORKING',
    SLEEPING = 'SLEEPING'
}


export enum EServerMode {
    STANDALONE = 'STANDALONE',
    MASTER = 'MASTER',
    SLAVE = 'SLAVE'
}