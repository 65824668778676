'use strict';

import angular = require("angular");
import { UserAccount } from "../../../../data/account.data";
import { CustomFeedbackResponseResponse } from "../../../../data/customFeedback.data";
import { Unit, Splitter, UnitSettings, EAddressCalculationMode } from "../../../../data/unit.data";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";

require('./unit.settings.modal.css')

/* @ngInject */
export default class UnitSettingsModalController {

    private isLoading: boolean;
    private hasOnlineService: boolean = false;
    private mode: string = 'GENERAL';
    private availableSplitters: Splitter[] = [];

    private addressMode_TEXT_PATTERN: boolean;
    private addressMode_COORDINATES_DEZ: boolean;
    private addressMode_COORDINATES_HEX: boolean;
    private addressMode_COORDINATES_GK: boolean;
    private addressMode_STREET_DICTIONARY: boolean;
    private addressMode_CODE: boolean;
    private addressMode_BUILDING: boolean;

  constructor(private $uibModalInstance: any,
        private dataService,
        private $log: angular.ILogService,
        private unit: Unit,
      public restService: RestService,
      public privilegeService: PrivilegeService,
        private customFeedbackList: CustomFeedbackResponseResponse[]
    ) {
        restService.loadAvailableSplitters().then(val => {
            this.availableSplitters = val;
        });
        for (let mode of unit.settings.addressCalculationModes) {
            this['addressMode_' + mode] = true;
        }

        this.hasOnlineService = (dataService.getAccount() as UserAccount).hasOnlineService;
    }

    close() {
        this.$uibModalInstance.close();
    }

    save() {
        this.isLoading = true;
        this.unit.settings.addressCalculationModes = this.getAddressCalculationModes();

        if (!this.unit.settings.hasCustomFeedback) {
            this.unit.settings.customFeedbackId = undefined;
        }

        if (this.unit.settings.doubleAlarmTime < 0) {
            this.unit.settings.doubleAlarmTime = 0
        }

        this.dataService.saveUnitSettings(this.unit, this.unit.settings, (data: UnitSettings) => {
            this.$log.debug(data);
            this.unit.settings = data;
            this.isLoading = false;
        },
            (response) => {
                //Error occured
                this.$log.error(response);
                this.isLoading = false;
            });
    }

    modes(): string[] {
        return Object.keys(EAddressCalculationMode).filter(k => typeof EAddressCalculationMode[k as any] === "number");
    }

    getSelectedCustomFeedback() {
        if (this.unit.settings.customFeedbackId === null) return "---";
        for (let feedback of this.customFeedbackList) {
            if (feedback.id === this.unit.settings.customFeedbackId) {
                return feedback.name;
            }
        }
        this.unit.settings.customFeedbackId = null
        return "---";
    }


    getAddressCalculationModes(): EAddressCalculationMode[] {
        let modes: EAddressCalculationMode[] = [];
        for (let mode of this.modes()) {
            if (this['addressMode_' + mode]) {
                modes.push(EAddressCalculationMode[mode])
            }
        }
        return modes;
    }


}