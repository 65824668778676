'use strict';

import {ILogService, IScope} from "angular";
import {Role, RolePrivilegeResponse} from "../../../../data/role.data";
import RestService from "../../../../services/rest.service";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
import {UserAccount} from "../../../../data/account.data";
import { is } from "angular-ui-router";

//Controller for functions and groups modal

require('./roles.css');

/* @ngInject */
export default class RolesModalController {
  public restService: RestService;
  public $scope: IScope;
  public $translate;
  public $uibModal: any;
  public $log: ILogService;
  public $uibModalInstance: any;

  public roles: Role[] = [];
  public activeRole: Role;
  public filteredPrivileges: RolePrivilegeResponse[] = [];
  public isLoading = true;
  public priv:PrivilegeService;
  public hasCreate: boolean= false;
  public hasEdit:boolean= false;
  public hasDelete:boolean= false;
  public account: UserAccount;
  public isOwnRole:boolean=false;
  public toggleAll = true;
  public toggleOnlyActive = false;
  public searchFilter: string = '';
  public isAdmin = false;

  constructor($scope: IScope, $translate, $uibModal, $uibModalInstance, restService: RestService, privilegeService:PrivilegeService, dataService) {
    this.$scope = $scope;
    this.$translate = $translate;
    this.$uibModal = $uibModal;
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.priv = privilegeService;
    this.account= dataService.getAccount() as UserAccount;
    this.isAdmin = this.account.admin;
    this.hasCreate = this.priv.has(RolePrivilege.User_Roles_Create);
    this.hasEdit = this.priv.has(RolePrivilege.User_Roles_Edit);
    this.hasDelete = this.priv.has(RolePrivilege.User_Roles_Delete);

    this.loadRoles();
  }

  close() {
    this.$uibModalInstance.close();
  }

  /**
   * Load roles
   */
  loadRoles(roleIdToSelect?: string) {
    this.isLoading = true;
    this.restService.loadRoles().then((roles) => {
      this.roles = roles;
      if (this.roles.length > 0) {
        if (roleIdToSelect) {
          const filtered = this.roles.filter(role => role.id === roleIdToSelect);
          if (filtered.length > 0) {
            this.selectActiveRole(filtered[0]);
          } else {
            this.selectActiveRole(this.roles[0]);
          }
        } else {
          this.selectActiveRole(this.roles[0]);
        }
      } else {
        this.selectActiveRole(undefined);
      }
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  filterForNonAdmin() {
    if (this.isAdmin) {
      return;
    }
    this.filteredPrivileges = this.filteredPrivileges.filter(priv => {
      if (priv.parentHierarchy.includes(RolePrivilege.Admin) && !priv.active) {
        return false;
      }
      return true;
    });
  }

  selectActiveRole(role: Role) {
    this.activeRole = role;
    this.checkIsOwnRole();
    this.filteredPrivileges = [];
    if (role) {
      role.privileges.forEach(priv => this.filteredPrivileges.push(priv));

      if (this.searchFilter) {
        this.onSearchFilterChanged();
      }
      this.filterForNonAdmin();
    }
  }
  checkIsOwnRole(){
    this.isOwnRole = this.account.role.id === this.activeRole.id;

  }

  onSearchFilterChanged() {
    this.filteredPrivileges = [];
    if (this.searchFilter) {
      const tmp = this.searchFilter.toLowerCase();
      this.activeRole.privileges.filter(priv => {
        for (let i = 0; i < priv.parentHierarchyTranslation.length; i++) {
          let element = priv.parentHierarchyTranslation[i];
          if (element.toLocaleLowerCase().includes(tmp)) {
            return true;
          }
        }
        return false;
      }).forEach(priv => this.filteredPrivileges.push(priv));
    } else {
      this.resetSearchFilter();
    }
    this.filterForNonAdmin();
  }

  resetSearchFilter() {
    this.searchFilter = '';
    this.filteredPrivileges = [];
    if (this.activeRole) {
      this.activeRole.privileges.forEach(priv => {
        if (!this.isAdmin && priv.parentHierarchy.includes(RolePrivilege.Admin)) {
          return;
        }
        this.filteredPrivileges.push(priv);
      });
    }
  }

  toggleAllActive() {

    if (this.toggleOnlyActive) {
      this.resetSearchFilter();
    } else {
      this.searchFilter = '';
      this.filteredPrivileges = [];
      this.activeRole.privileges.forEach(priv => {
        if (!this.isAdmin && priv.parentHierarchy.includes(RolePrivilege.Admin)) {
          return;
        }
        if (priv.active) {
          this.filteredPrivileges.push(priv);
        }
      });

    }
    this.toggleOnlyActive = !this.toggleOnlyActive;
  }

  selectPrivilege(element: RolePrivilegeResponse) {
    if (this.activeRole.shared) return;
    element.active = !element.active;
  }

  selectAll() {
    if (this.isOwnRole){
      return;
    }
    if (!this.hasEdit) return;
    if (!this.activeRole) return;
    if (this.activeRole.shared) return;

    this.resetSearchFilter();

    if (this.toggleAll) {
      this.activeRole.privileges.forEach(priv => {
        if (!this.isAdmin && priv.parentHierarchy.includes(RolePrivilege.Admin)) {
          return;
        }
        priv.active = true;
      });
    } else {
      this.activeRole.privileges.forEach(priv => priv.active = false);
    }

    this.toggleAll = !this.toggleAll;

    this.filterForNonAdmin();
  }



  /**
   * Add a new role
   */
  addRole() {
    this.isLoading = true;

    this.$translate('USERS.ROLE_DEFAULT_NAME').then((translation) => {
      this.restService.addRole(translation).then((addedRole) => {
        this.loadRoles(addedRole.id);
      }).finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });
    });


  }

  /**
   * Add a new role
   */
  saveRole() {
    if (!this.activeRole) return;
    this.isLoading = true;
    this.restService.saveRole(this.activeRole).then((result) => {
     this.roles= this.roles.map(role=> {
        if(role.id=== result.id){
          return result;
        }
        else {
          return role;
        }
      });
      this.selectActiveRole(result);
    }).finally(() => {
      this.isLoading = false;
      this.toggleOnlyActive = false;
      this.$scope.$applyAsync();
    });
  }

  /**
   * Delete an existing role
   */
  deleteRole() {
    if (!this.activeRole) return;
    if (!this.hasDelete)return;
    if (this.activeRole.inUse){
      this.openSaveDeleteModal();
    }else {
      this.makeDeleteRequest();
    }


  }

  private makeDeleteRequest() {
    this.isLoading = true;
    this.restService.deleteRole(this.activeRole.id).then(() => {
      this.loadRoles();
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  /**
   * Duplicate an existing role
   */
  duplicateRole() {
    if (!this.activeRole) return;
    this.isLoading = true;
    this.restService.duplicateRole(this.activeRole).then(() => {
      this.loadRoles();
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  private openSaveDeleteModal() {
     let modalInstance=  this.$uibModal.open({
       template: require('../savedelete.roles.modal/savedelete.roles.modal.html'),
       controller: 'SavedeleteRolesModalController',
       controllerAs: 'ctrl',
       size: 'md',
       resolve: {
         okFunction: ()=>{
           return (doDelete)=>{
             if (doDelete){
               this.makeDeleteRequest();
             }
           }
         }
       }
       }
     );

  }
}
