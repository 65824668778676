/* @ngInject */
import {IScope, ILogService, IRootScopeService} from "angular";
import RestService from "../../../../services/rest.service";
import {FeedbackUnitGroupSimple} from "../../../../data/admin.data";
import * as angular from "angular";
export default class SingleFeedbackGroupModalController {
  public $scope: IScope;
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public $uibModalInstance: any;
  public restService: RestService;
  public okFunction: any;
  public feedbackGroupSimple: FeedbackUnitGroupSimple;
  public isNew: boolean = false;
  public isSaving: boolean= false;
  public name: string;
  public delayTime:number;

  constructor($scope: IScope, $log: ILogService, $rootScope: IRootScopeService, $uibModalInstance, restService: RestService,okFunction ,feedbackGroupSimple: FeedbackUnitGroupSimple){
    this.$scope = $scope;
    this.$log = $log;
    this.$rootScope = $rootScope;
    this.$uibModalInstance = $uibModalInstance;
    this.feedbackGroupSimple = feedbackGroupSimple;
    this.restService = restService;
    this.okFunction = okFunction;

    if (angular.isUndefined(feedbackGroupSimple)){
      this.isNew = true;
      this.delayTime= 60;
    }else {
      this.name = feedbackGroupSimple.name;
      this.delayTime = feedbackGroupSimple.delayTime;
      this.isNew= false;
    }

  }
  cancel() {
    this.$uibModalInstance.close();
    this.okFunction(false);
  }
  saveSingleFeedbackGroup() {
    this.isSaving = true;
   if (this.isNew){
     var feedbackGroupNew= {name: this.name,
     delayTime: this.delayTime} as FeedbackUnitGroupSimple;
     this.restService.createFeedbackUnitGroup(feedbackGroupNew).then((success)=>{

     }).finally(()=>{
       this.isSaving = false;
       this.$uibModalInstance.close();
       this.okFunction(true);
     });
   }else {
     this.feedbackGroupSimple.name = this.name;
     this.feedbackGroupSimple.delayTime = this.delayTime;
     this.restService.updateFeedbackUnitGroupWithoutUnits(this.feedbackGroupSimple).finally(()=>{
       this.isSaving = false;
       this.$uibModalInstance.close();
       this.okFunction(true);
     });
   }

  }
}