'use strict';

export default class UnitSelectComponent {
  constructor() {
    this.restrict = 'A',
    this.scope = {
      sound: '='
    }
    this.template = require('./unit.select.component.html');

    this.controller = UnitSelectController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//unitSelect
/* @ngInject */
class UnitSelectController {
  constructor($scope, $log, dataService) {
    this.$scope = $scope;
    this.$log = $log;
    this.dataService = dataService;
    
    var account = dataService.getAccount();

    this.$log.debug('Init unit select directive');

    this.dataService.getUnits(false, (units) => {
      this.units = units[account.username];
    }, (err) => {
      //Did not work
      this.$log.error('Could not load all units');
    });
  }

  select(unit) {
    this.sound.name = unit.name;
    this.sound.matching = true;
  }
}
