/* @ngInject */
import {IScope, ILogService, IRootScopeService} from "angular";
import {InputPluginDetails} from "../../../../data/input.data";
import * as angular from "angular";
import {BaseField, BaseFieldType} from "../../../../data/basefield.data";
import RestService from "../../../../services/rest.service";
require('./edit.input.modal.scss')
export default class EditInputModalController {
  public $scope: IScope;
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public $uibModalInstance: any;
  public inputPlugin: InputPluginDetails;
  public additionalTabs: AdditionalTab[] = [];
  public hasOptionalSettings:boolean=false;
  public tab: string = CurrentTab.SETTINGS;
  public fields: any[] = [];
  public hasSettings:boolean= false;
  public units: any;
  public restService:RestService;
  public isSaving:boolean= false;
  public triggerReload: boolean= false;
  public okFunction: any;

  constructor($scope: IScope, $log: ILogService, $rootScope: IRootScopeService, $uibModalInstance, $uibModal, inputPlugin, dataService, restService:RestService, okFunction){
    this.$scope = $scope;
    this.$log = $log;
    this.$rootScope = $rootScope;
    this.$uibModalInstance = $uibModalInstance;
    this.inputPlugin= inputPlugin;
    this.restService = restService;
    this.triggerReload= false;
    this.okFunction= okFunction;

    this.inputPlugin.pluginData.forEach((field: BaseField) => {
      if (field.optional) {
        this.hasOptionalSettings = true;
      }
      // Gather categories
      if (field.category) {
        const tab = this.additionalTabs.filter(entry => entry.category === field.category);
        if (tab.length === 0) {
          const newTab = {
            category: field.category,
            fields: []
          } as AdditionalTab;
          this.additionalTabs.push(newTab);
          tab.push(newTab);
        }
        tab[0].fields.push(field);
      }
    });
    this.selectTab(CurrentTab.SETTINGS);

    restService.loadUnitsForBaseField().then((response)=>{
      this.units = response;
  })


  }

  /**
   * expand method to add new icons here input plugin tabs
   * @param category
   */
  getIconTab(category: string){
    if (category === 'ALARM' || category === 'COBRA_ALARM'){
      return 'fad fa-siren-on fa-fw header-icon';
    }
    if (category === 'SPLITTER'){
      return 'fad fa-line-columns header-icon'
    }
    if(category === 'SECURITY'){
      return 'fad fa-shield-alt header-icon'
    }
    if (category === 'MISC'){
        return '';
    }
    return 'fad fa-fw fa-stream header-icon';
  }
  selectTab(selectTab:string){
    this.tab = selectTab;
    this.fields = [];
    this.inputPlugin.pluginData.forEach((field: BaseField) => {
      switch (field.type) {
        case BaseFieldType.PERSON_SELECT:
        case BaseFieldType.DATABASE_PERSON_SELECT:
          break;
        default:
          switch (selectTab) {
            case CurrentTab.SETTINGS:
              if (!field.optional && !field.category) {
                this.fields.push(field);
              }
              break;
            case CurrentTab.OPTIONAL:
              if (field.optional && !field.category) {
                this.fields.push(field);
              }
              break;
            default:
              // Additional category
              if (field.category === this.tab) {
                this.fields.push(field);
              }
              break;
          }

      }
    });
    this.hasSettings = this.fields.length > 0;
  }

  getIcon(): string {
    if (angular.isUndefined(this.inputPlugin.simpleInputClassName)) return '';

    const iconPath = `/img/static/input/${this.inputPlugin.simpleInputClassName.replace(/.*\./, '')}.png`;
    return iconPath;
  }
  /**
   * Close the modal
   */
  cancel() {
    this.$uibModalInstance.close();
  }

  save(){
    this.isSaving= true;
    this.restService.saveInputPluginDetailed(this.inputPlugin).then(()=>{
      this.triggerReload = true
    }).finally(()=>{
      this.isSaving= false;
      this.$uibModalInstance.close();
      this.okFunction(this.triggerReload);
    })
  }

}
enum CurrentTab {
  SETTINGS = 'SETTINGS',
  OPTIONAL = 'OPTIONAL'
}
interface AdditionalTab {
  category: string,
  fields: BaseField[]
}