import { ILogService, IRootScopeService, IScope } from "angular";
import { AAOTime, AAOTimeDay } from "../../../../../../data/aao.data";

require('./aao.day.css');

export default class AAODayComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public require: any;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./aao.day.html');
    this.scope = {
      entry: '=',
      day: '='
    };
    this.controller = AAODayComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AAODayComponentController {
  public $log: ILogService;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public isLoading: boolean;

  public day = '';
  public entry: AAOTimeDay;

  constructor($log: ILogService, $scope: IScope, $rootScope: IRootScopeService) {
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$scope = $scope;
  }

  public getToolTip() {
    if (!this.entry) return '';
    return this.entry.start + ' - ' + this.entry.end;
  }
}