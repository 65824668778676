'use strict';

import { AlarmVehicle } from "../../../../data/alarm.data";

require('./alarm.vehicles.component.css');

//alarmEinsatzmittel
export default class AlarmVehiclesComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controllerAs: string;
  public bindToController: boolean;
  public controller: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./alarm.vehicles.component.html');
    this.scope = {
      alarm: '='
    };
    this.controllerAs = 'ctrl';
    this.bindToController = true;
    this.controller = AlarmVehiclesComponentController;

  }
}

class AlarmVehiclesComponentController {
  private $uibModal;
  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  openVehicle(vehicle: AlarmVehicle) {
    this.$uibModal.open({
      template: require('./../../../modals/wache/vehicle.modal/vehicle.modal.html'),
      controller: 'VehicleModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        vehicle: () => {
          return undefined;
        },
        vehicleId: () => {
          return vehicle.vehicleId;
        },
        okFunction: () => {

        }
      }
    });
  }
}