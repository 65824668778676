'use strict';

import { AAOCondition, AAOEntryResponseElement } from "../../../../data/aao.data";
import PrivilegeService from "../../../../services/privilege.service";

require('./aao.conditions.css');

/* @ngInject */
export default class AAOConditionsModalController {
  public $uibModalInstance: any;

  public entry: AAOEntryResponseElement;
  public okFunction: Function;

  // Validation
  public isEndInvalid = false;
  public isStartInvalid = false;


  constructor($uibModalInstance, entry: AAOEntryResponseElement, okFunction: Function, public privilegeService: PrivilegeService) {
    this.$uibModalInstance = $uibModalInstance;
    this.entry = entry;
    this.okFunction = okFunction;
  }

  addCondition() {
    this.entry.conditions.push({
      value: '',
      field: this.entry.defaultConditionField
    });
  }

  removeCondition(condition: AAOCondition) {
    const index = this.entry.conditions.indexOf(condition);
    if (index > -1) {
      this.entry.conditions.splice(index, 1);
    }
  }

  close() {
    this.$uibModalInstance.close();
  }

  save() {
    this.okFunction();
    this.$uibModalInstance.close();
  }

  isNotValid(): boolean {
    for (let i = 0; i < this.entry.conditions.length; i++) {
      let condition = this.entry.conditions[i];
      if (condition.value === '' || condition.field === '') return true;
    }
    return false;
  }


}
