'use strict';

import { IScope } from "angular";
import angular = require("angular");
import * as L from "leaflet";


/* @ngInject */
export default class ChooseAlarmOnMapController {
  public $uibModalInstance: any;
  public $scope: IScope;
  public mapService: any;
  public dataService: any;
  public coords: MapCoords;
  public okFunction: any;

  public marker: L.Marker;
  public map;

  public isStatic = false;

  constructor($uibModalInstance, $scope: IScope, $document, mapService, dataService, coords: MapCoords, okFunction) {
    this.$uibModalInstance = $uibModalInstance;
    this.$scope = $scope;
    this.mapService = mapService;
    this.dataService = dataService;
    this.coords = coords;
    this.okFunction = okFunction;
    const settings = this.dataService.getAccount().settings;

    if (angular.isUndefined(this.coords.lat)) {
      this.coords = {
        lat: settings.lat,
        lng: settings.lng
      } as MapCoords;
    } else {
      // If accuracy is present, this is a static map
      this.isStatic = coords.accuracy !== undefined;
    }

    $document.ready(() => {
      this.initMap();
    });
  }

  /**
   * Init the map
   */
  initMap() {
    L.Icon.Default.imagePath = '/img/static';
    this.map = L.map('mapid').setView([this.coords.lat, this.coords.lng], 13);

    this.map.attributionControl.setPrefix('<a style="color:black !important" href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a>');

    let layers = this.mapService.getBaseLayers();
    
    L.control.layers(layers).addTo(this.map);

    let selectedLayer = this.mapService.getSelectedLayer();
    if(selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(this.map);

    let mapService = this.mapService
    this.map.on('baselayerchange', function(e) {
      mapService.saveLayer(e.name);
    });

    const latLng = L.latLng(this.coords.lat, this.coords.lng);

    if (this.coords.accuracy) {
      const circleobj = L.circle(latLng, this.coords.accuracy);
      circleobj.addTo(this.map);
    }

    this.marker = L.marker(latLng, { draggable: !this.isStatic }).addTo(this.map);

    if (!this.isStatic) {
      this.map.on('click', (ev) => {
        this.markerMovedEvent(ev.latlng);
      });
      this.marker.on('dragend', (e) => {
        this.markerMovedEvent(e.target.getLatLng());
      });
    }
  }

  /**
   * Should be triggered, if marker should be moved
   * @param latLng 
   */
  markerMovedEvent(latLng: L.LatLng) {
    this.marker.setLatLng(latLng);
    this.coords.lat = latLng.lat;
    this.coords.lng = latLng.lng;
    this.$scope.$applyAsync();
  }

  /**
   * Return the new lat/lng and close modal
   */
  ok() {
    this.$uibModalInstance.close();
    const currentLatLng = this.marker.getLatLng();
    this.okFunction({
      lat: currentLatLng.lat,
      lng: currentLatLng.lng
    });
  }
}

export interface MapCoords {
  lat: number,
  lng: number,
  accuracy: number
}