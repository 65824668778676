'use strict';

import { IHttpService, IRootScopeService, IScope, IWindowService } from "angular";
import { AlarmObject } from "../../../../data/objects.data";
import HelperService from "../../../../services/helper.service";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";

require('./object.overall.component.css')

//objectOverall
export default class ObjectOverallComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'A'
    this.template = require('./object.overall.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '=',
      form: '='
    };
    this.controller = ObjectOverallComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectOverallComponentController {
  public $window: IWindowService;
  public $http: IHttpService;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public restService: RestService;
  public dataService: any;
  public helperService: any;
  public types = ['RETIREMENT_HOME',
    'GOVERNMENT',
    'OTHER',
    'REFUGEE_HOME',
    'BAB',
    'TRAIN_STATION',
    'BANK',
    'DISABLED_HOME',
    'ASSISTED_LIVING',
    'BS',
    'CHEMICAL_INDUSTRY',
    'FIRE_STATION',
    'AIRPORT',
    'BUILDING',
    'BUILDING_WITH_PV',
    'BUSINESS',
    'HARBOR',
    'HOTEL',
    'SKYSCRAPER',
    'INDUSTRY',
    'VIDEO',
    'SCHOOL',
    'CHURCH',
    'POWER_PLANT',
    'HOSPITAL',
    'STORAGE_HALL',
    'MUSEUM',
    'PARKING_LOT',
    'RESTAURANT',
    'KINDERGARDEN',
    'MISC',
    'SPORTS_CENTER',
    'STADIUM',
    'CARRIER',
    'GAS_STATION',
    'TANK_FARM',
    'THEATRE',
    'TUNNEL'
  ];
  public firstTime = true;
  public templates: any;
  public selectedTemplate: any;
  public alarmObject: AlarmObject;
  public icon: any;

  constructor($scope: IScope, $rootScope: IRootScopeService, $window: IWindowService,
    $http: IHttpService, dataService, helperService: HelperService, public privilegeService: PrivilegeService, restService: RestService) {
    this.$window = $window;
    this.$http = $http;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.restService = restService;
    this.dataService = dataService;
    this.helperService = helperService;

    this.templates = this.dataService.getAccount().exportTemplates;

    if (this.templates.length > 0) {
      this.selectedTemplate = this.templates[0];
    }

    this.$scope.$watch('ctrl.alarmObject', () => {
      if (this.firstTime) {
        this.firstTime = false;
        return;
      }
      //Only update object after object has been loaded from FE2
      this.changeType(this.alarmObject.type);

      //Load map for first time
      this.$rootScope.$emit('alarm.object.map.show', {
        type: 'OVERALL',
        coords: this.alarmObject
      });
    });
  }

  changeType(newType) {
    this.alarmObject.type = newType;
    //Update icon
    this.icon = this.helperService.getIconForObjectType(newType);
  };

  openDownloadUrl(mode) {
    this.$window.open(this.restService.getBaseUrl() + '/objects/' + this.alarmObject.id + '/export?mode=' + mode + '&templateName=' + encodeURIComponent(this.selectedTemplate) + '&Authorization=' + this.$http.defaults.headers.common.Authorization, '_blank');
  }

  selectTemplate(newTemplate) {
    this.selectedTemplate = newTemplate;
  }
}
